import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';

export default class BookAppointmentSection extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }




  render() {
    //const ExampleObject = ( <div></div> );

    return (
	  <FluidContainer id="bookappointmentSectionID" fluid>
	    <InternalContainer>
	      <h1>
	      	<span className="red">B</span>
	      	<span className="orange">O</span>
	      	<span className="yellow">O</span>
	      	<span className="green">K</span>
	      	&nbsp;
	      	<span className="blue">A</span>
	      	<span className="purple">P</span>
	      	<span className="pink">P</span>
	      	<span className="red">O</span>
	      	<span className="orange">I</span>
	      	<span className="yellow">N</span>
	      	<span className="green">T</span>
	      	<span className="blue">M</span>
	      	<span className="purple">E</span>
	      	<span className="pink">N</span>
	      	<span className="red">T</span>
	      </h1>
		  
		  <HeaderFontP>Here is where your dreadful bliss begins!</HeaderFontP>

		  <p className="red">
		      Please complete one of the forms below if you would like to contact me for an estimate.
		  	  Let me know ahead of time if you are traveling from out of state and I can help you plan your visit efficiently.
		  	  There are hotel options within 2 miles of me if you plan on staying for more than one day.
		  </p>
			
		  <p className="orange"> 
			  My studio is a private, at home studio. Because of this, it is a private address. I am located
			  in Wellsboro, PA. If you have a confirmed appointment with me, you will get a confirmation email which includes my address.
			  There is plenty of off-street parking at my studio. There is also plenty of room for guests! Just let me know ahead of time
			  if you are bringing anyone!
		  </p>
			
		  <HeaderFontP className="green">Some things to keep in mind...</HeaderFontP>
		
		  <p className="blue">
		  	  Starting a new set of dreadlocks does require a $50 deposit. Don't worry; you don't have to pay that now! Once you get a
		  	  confirmed appointment time and date, I will send you an email with the information on how to make the deposit. This deposit 
		  	  will be used as a placeholder for your appointment and is refundable up to 48 hours prior to the appointment if you can 
		  	  no longer make it. The deposit will come off the total cost of the final balance. Starting new dreadlocks is $65 an hour.
		  </p>

		  <p className="purple">
			  Dreadlock maintenance requires a $50 deposit. Don't panic; you don't have to pay that now! Once you get a confirmed
			  appointment time and date, I will send you an email with the information on how to make the deposit. This deposit will be used as
			  a placeholder for your appointment and is refundable up to 48 hours prior to the appointment if you can no longer make it. 
			  The deposit will come off the total cost of the final balance. Dreadlock maintenance is $55 an hour.
		  </p>

		  <p className="pink">
			  Do you have any questions I haven't answered for you? Check out my FAQs section or send me an email
			  at <EmailA href="mailto:dreadfulblisscasey@gmail.com">dreadfulblisscasey@gmail.com</EmailA>. I look forward to helping you start your 
			  DREADFUL Journey!
		  </p>
			

		  <NewDreadsButton tag={Link} to="/BookAppointmentNewDreads">New Dreadlocks Form</NewDreadsButton>
		  <br /> 
		  <MaintenanceRepairButton tag={Link} to="/BookAppointmentMaintenanceRepair">Maintenance or Repair Form</MaintenanceRepairButton>
		  
		  {/*<Link className="blue" to="/BookAppointmentCustomExtensions">Custom Extensions</Link> <br />*/}

	    </InternalContainer>
	  </FluidContainer>

		);
	}
}

const NewDreadsButton = styled(Button)`
	display: block!important;
	background: #D0021B!important;
	border: #D0021B!important;
	margin: 0 auto;
	width: 290px;
	height: 50px;
	color: black!important;
	font-size: 22px!important;
	line-height: 22px;!important;
	:hover { transform: translateY(3px); }
	${media.xs400` font-size: 26px!important; line-height: 26px!important; width: 340px; `}
	${media.sm` font-size: 32px!important; line-height: 32px!important; width: 450px; `}
	${media.lg` font-size: 36px!important; line-height: 36px!important; width: 500px; `}
`;

const MaintenanceRepairButton = styled(Button)`
	display: block!important;
	background: #F5A623!important;
	border: #F5A623!important;
	margin: 0 auto;
	width: 290px;
	height: 50px;
	color: black!important;
	font-size: 22px!important;
	line-height: 22px!important;
	:hover { transform: translateY(3px); }
	${media.xs400` font-size: 26px!important; line-height: 26px!important; width: 340px; `}
	${media.sm` font-size: 32px!important; line-height: 32px!important; width: 450px; `}
	${media.lg` font-size: 36px!important; line-height: 36px!important; width: 500px; `}

`;

const FormLink = styled(Link)`
	font-size: 32px!important;

`;


const HeaderFontP = styled.p` 
	font-family: "Permanent Marker"!important;
`;

const DisplayRow = styled(Row)`

`;

const FluidContainer = styled(Container)`
	padding: 20px 0 20px 0!important;
	background: black;
`;
const InternalContainer = styled(Container)`
	text-align: center;
	h1 { 
		color: black; 
		margin: 0 0 20px 0;
	}
	
	p {color: white; text-align: center;}
	a { display: inline-block;}
`;

const EmailA = styled.a`
	color: white!important;
`;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/