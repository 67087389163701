import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import media from '../MediaQueries.js';
import styled from 'styled-components';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class Sitemap extends Component {
  //static propTypes = {testProp: PropTypes.bool.isRequired}

  constructor(props) {
    super(props);

    this.state = {
      sitemapList: []
    };
  }

  componentWillMount() {
    this.setSitemapTxt();
  }

  setSitemapTxt = () => {
    axios.get('https://s3.amazonaws.com/dreadfulblissstorage/sitemap.json')
      .then(response => {
        this.setState({
          sitemapList: response.data
        });
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error);
      });
  }

  render() {
    const SitemapView = this.state.sitemapList.map((item, index, array) => {
      return <p key={index}>  
             {item.url}
            </p>
    });

    return (
    	<FluidContainer fluid>
        <Helmet>
          <title>Sitemap - Dreadful Bliss</title>
        </Helmet>
        <InternalContainer>
	        
      		{/*ExampleObject*/}
      		<h1>Sitemap</h1>
          {SitemapView}
        </InternalContainer>
      </FluidContainer>
		);
	}
}

const FluidContainer = styled(Container)` 
  padding: 30px 0 20px 0!important;  

`;
const InternalContainer = styled(Container)` 
  h1 {  }  
  p { margin: 12px 0 0 0; max-width: 100%!important; font-size: 8px!important; ${media.sm` font-size: 14px!important;`} }

`;

//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
`;
*/
