import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, FormGroup, Form,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle, Label, NavDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

/*
    Pure Maple Products -  Syrup, Cream, Candy, Sugar
    Extra Value Products - Sauces, Beverages, Candy, Jelly, Pancake Mixes, Gift Sets
    Non Maple Products -   Informational, Clothes, Candles, Kitchenware, Books 
*/

export default class FormLoadingPopup extends Component {
  static propTypes = {
    onAfterOpen: PropTypes.func.isRequired,
    formLoadingPopupClosing: PropTypes.func.isRequired,
    formLoadingPopupOpen: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }


  render() {

    return (
        
      	<FluidContainer  fluid>
          { this.props.formLoadingPopupOpen ? 
          <Modal
            isOpen={this.props.formLoadingPopupOpen}
            onAfterOpen={this.props.onAfterOpen}
            onRequestClose={this.props.formLoadingPopupClosing}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            style={formLoadingPopup}
            contentLabel="New Dreadlocks Form Submitting..." >
            <h3 style={{marginBottom: "35px"}}>New Dreadlocks Form Submission</h3>

            
            <SavingP className="submitting blue">Submitting form<span>.</span><span>.</span><span>.</span></SavingP>

          </Modal>
          : '' }
        </FluidContainer>
        
		);
	}
}

const SavingP = styled.p`
  font-size: 30px!important;
  text-align: center;

  span {
    font-size: 50px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    :nth-child(2) {
      animation-delay: .2s;
    }
    :nth-child(3) {
      animation-delay: .4s;
    }
  }


@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

`;




const FluidContainer = styled(Container)`
  padding: 0!important;
`;

const formLoadingPopup = {
  overlay: {
    backgroundColor       : "rgba(0, 0, 0, 0.6)",
    zIndex: "5",
    width: "100%",
    margin: "0 auto"
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    border: "1px solid lightgray",
    padding: "15px",
    backgroundColor: "white",
    color: "black",
    fontFamily: "Short Stack",
    fontSize: "20px",
    textAlign: "center",
    width: "90%",
    maxWidth: "500px"
  }
};


//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/