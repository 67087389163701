
import React from 'react';
import { withRouter } from 'react-router-dom';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, Input, FormGroup, Label } from 'reactstrap';

import { Link } from 'react-router-dom'
import axios from 'axios';

import { Formik, Field, Form, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Dropzone from "react-dropzone";
import Thumb from "./Thumb";
import AWS from 'aws-sdk';
import moment from 'moment';
import FormLoadingPopup from '../components/FormLoadingPopup.js';

// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/contact
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/customextensions
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/maintenancerepair
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/newdreads

// Initialize the Amazon Cognito credentials provider

const sleep = m => new Promise(r => setTimeout(r,m))

const doS3Upload = async (values) => {

  const picLinkObject = {
    currentHairPicsLinks: []
  }

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:2c4b16a0-2c15-4ee2-8123-7e3bb2ef503b'
    })
  })
  
  let s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: 'dreadfulblissstorage'}
  })

  // CurrentHairPics
  let currentHairPicsLocations_Promises = values.currentHairPics.map(async (pic) => {
    let locationPromise = await s3.upload({
      Key: (encodeURIComponent('maintenancerepair') + '/' + encodeURIComponent(values.firstName + '_' + values.lastName) + '/' 
        + encodeURIComponent('CurrentHairPics') + '/' + pic.name),
      Body: pic,
      ACL: 'public-read'
    }).promise();
    
    return locationPromise;
  })


  let currentHairPicsLinks_AllInfo =  await Promise.all(currentHairPicsLocations_Promises);

  picLinkObject.currentHairPicsLinks = currentHairPicsLinks_AllInfo.map( (picInfo) => { return picInfo.Location; })

  return picLinkObject;
}

const sendEmail = async (values, picLinkObject) => {
  let URLCasey = 'https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/maintenancerepair';
  let URLCustomer = 'https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/maintenancerepairconfirmation';
  
  let name = values.firstName + ' ' + values.lastName;
  let possibleAppointmentDatesArray_NewFormat = [];
  let possibleAppointmentDatesStr = "";
  let currentHairPicsLinksStr = "";
  let preferredContactMethodString = '';
  let preferredContactMethodArray = [];
  let bestDaysOfWeekArray = [];
  let bestDaysOfWeekString = '';
  let bestTimesOfDayArray = [];
  let bestTimesOfDayString = '';
  let dreadInfoArray = [];
  let dreadInfoString = '';
  
  
  // Make all checkbox "select all that apply" fields be a pretty string for the email.

  Object.keys(values.preferredContactMethod).forEach(key => {
    const keyValue = values.preferredContactMethod[key];

    if(keyValue === true) {
      preferredContactMethodArray.push(key);
    }
  })
  preferredContactMethodString = preferredContactMethodArray.join(", ");

  Object.keys(values.bestDaysOfWeek).forEach(key => {
    const keyValue = values.bestDaysOfWeek[key];

    if(keyValue === true) {
      bestDaysOfWeekArray.push(key);
    }
  })
  bestDaysOfWeekString = bestDaysOfWeekArray.join(", ");

  Object.keys(values.bestTimesOfDay).forEach(key => {
    const keyValue = values.bestTimesOfDay[key];

    if(keyValue === true) {
      bestTimesOfDayArray.push(key);
    }
  })
  bestTimesOfDayString = bestTimesOfDayArray.join(", ");

  Object.keys(values.dreadInfo).forEach(key => {
    const keyValue = values.dreadInfo[key];

    if(keyValue === true) {
      dreadInfoArray.push(key);
    }
  })
  dreadInfoString = dreadInfoArray.join(", ");

  values.possibleAppointmentDates.map((date) => {
    let newDate = moment(date.toString()).format("MM/DD/YYYY");
    possibleAppointmentDatesArray_NewFormat.push(newDate);
  })

  // End of Make all checkbox "select all that apply" fields be a pretty string for the email section.
  
  possibleAppointmentDatesArray_NewFormat.length > 0 ?  possibleAppointmentDatesStr = possibleAppointmentDatesArray_NewFormat.join(", ") : possibleAppointmentDatesStr = ""
  picLinkObject.currentHairPicsLinks.length > 0 ?  currentHairPicsLinksStr = picLinkObject.currentHairPicsLinks.join(" ||||| ") : currentHairPicsLinksStr = ""
  
  let emailData = {
    name: name,
    email: values.email,
    phone: values.phone,
    originPlace: values.originPlace,
    preferredContactMethod: preferredContactMethodString,
    parentalConsent: values.parentalConsent,
    bestDaysOfWeek: bestDaysOfWeekString,
    bestTimesOfDay: bestTimesOfDayString,
    possibleAppointmentDates: possibleAppointmentDatesStr,
    firstTimeSeeingLoctician: values.firstTimeSeeingLoctician,
    dreadInfo: dreadInfoString,
    dreadAge: values.dreadAge,
    haveHowManyDreads: values.haveHowManyDreads,
    canYouTouchOrSeeScalp: values.canYouTouchOrSeeScalp,
    scalpHealth: values.scalpHealth,
    currentHairPicsLinks: currentHairPicsLinksStr,
    additionalInfo: values.additionalInfo
  };
  

  // Send email to Casey
  fetch(URLCasey, {
    method: 'POST',
    
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify({emailData})
  })
  .then(function (response) {
    //console.log('Response: ' + JSON.stringify({emailData}));
  })
  .then(function (result) {
    //console.log('Result: ' + result);
  })
  .catch(function(error) {
    //console.log('Error: ' + error);
  });

  // Send confirmation email to customer

  fetch(URLCustomer, {
    method: 'POST',
    
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify({emailData})
  })
  .then(function (response) {
    //console.log('Response: ' + JSON.stringify({emailData}));
  })
  .then(function (result) {
    //console.log('Result: ' + result);
  })
  .catch(function(error) {
    //console.log('Error: ' + error);
  });
}


const MaintenanceRepairForm = (props) => {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, 
    status, setStatus, setFieldValue, possibleAppointmentDates, handleDayClick, goToHome } = props;

    return (
      <FormikForm onSubmit={handleSubmit} autoComplete="on">

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="firstName"><p className="red">FIRST NAME</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="firstName" />
            <ErrorMessage_Ext name="firstName" component="div" />  
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="lastName"><p className="orange">LAST NAME</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="lastName" />
            <ErrorMessage_Ext name="lastName" component="div" />  
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="email"><p className="green">EMAIL ADDRESS</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="email" />
            <ErrorMessage_Ext name="email" component="div" />
          </Col>
        </Row>


        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="phone"><p className="blue">PHONE NUMBER</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="phone" />
            <ErrorMessage_Ext name="phone" component="div" />
          </Col>
        </Row>

        <Row style={{marginBottom: "40px"}}>
          <LabelCol xs="12" sm="4">
            <label htmlFor="originPlace"><p className="purple">STATE OR COUNTRY YOU WILL BE TRAVELING FROM</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="originPlace" />
            <ErrorMessage_Ext name="originPlace" component="div" />
          </Col>
        </Row>
        
        <CheckboxMainDiv>
          <label><p className="pink">HOW WOULD YOU PREFER I CONTACT YOU? PLEASE CHECK ALL THAT APPLY.</p></label>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="preferredContactMethod.textMsg" type="checkbox" value={"values.preferredContactMethod.textMsg"} 
                    error={"touched.preferredContactMethod.textMsg" && "errors.preferredContactMethod.textMsg"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              TEXT ME!
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink"  check>
              <Input name="preferredContactMethod.call" type="checkbox" value={"values.preferredContactMethod.call"} 
                    error={"touched.preferredContactMethod.call" && "errors.preferredContactMethod.call"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              CALL ME!
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink"  check>
              <Input name="preferredContactMethod.email" type="checkbox" value={"values.preferredContactMethod.email"} 
                    error={"touched.preferredContactMethod.email" && "errors.preferredContactMethod.email"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              EMAIL ME!
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="preferredContactMethod" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="red">
            IF YOU ARE UNDER THE AGE OF 18, DO YOU HAVE PARENTAL CONSENT? IF YOU ARE UNDER 18, CHOOSE YES, AND YOUR LEGAL GUARDIAN MUST EITHER
            ACCOMPANY YOU, OR YOU MUST FILL OUT A MINOR CONSENT FORM, SIGNED BY YOUR LEGAL GUARDIAN IF YOU ARE COMING ALONE. 
            I WILL EMAIL YOU THE FORM IF YOU CHOOSE YES.
          </p>
          </label>

          <FormGroup check>
            <Label className="red" check>
              <Input name="parentalConsent" type="radio" value="My guardian consents" checked={values.parentalConsent === "My guardian consents"}
                    error={"touched.parentalConsent" && "errors.parentalConsent"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              YES, AND MY LEGAL GUARDIAN CONSENTS
            </Label>
          </FormGroup>
          
          <FormGroup check>
            <Label className="red" check>
              <Input name="parentalConsent" type="radio" value="I am 18" checked={values.parentalConsent === "I am 18"}
                    error={"touched.parentalConsent" && "errors.parentalConsent"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I AM 18 OR OLDER
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="parentalConsent" component="div"/>  
        </CheckboxMainDiv>
        
        <CheckboxMainDiv>
          <label>
          <p className="orange">
            WHAT DAYS OF THE WEEK ARE BEST FOR YOU? MOST APPOINTMENTS ARE AVAILABLE ON WEEKDAYS, BUT I TAKE WEEKEND APPOINTMENTS 
            IF THAT IS ALL THAT WORKS FOR YOU. PLEASE CHECK ALL THAT APPLY.
          </p>
          </label>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.monday" type="checkbox" value={"values.bestDaysOfWeek.monday"} 
                    error={"touched.bestDaysOfWeek.monday" && "errors.bestDaysOfWeek.monday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Monday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.tuesday" type="checkbox" value={"values.bestDaysOfWeek.tuesday"} 
                    error={"touched.bestDaysOfWeek.tuesday" && "errors.bestDaysOfWeek.tuesday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Tuesday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.wednesday" type="checkbox" value={"values.bestDaysOfWeek.wednesday"} 
                    error={"touched.bestDaysOfWeek.wednesday" && "errors.bestDaysOfWeek.wednesday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Wednesday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.thursday" type="checkbox" value={"values.bestDaysOfWeek.thursday"} 
                    error={"touched.bestDaysOfWeek.thursday" && "errors.bestDaysOfWeek.thursday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Thursday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.friday" type="checkbox" value={"values.bestDaysOfWeek.friday"} 
                    error={"touched.bestDaysOfWeek.friday" && "errors.bestDaysOfWeek.friday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Friday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.saturday" type="checkbox" value={"values.bestDaysOfWeek.saturday"} 
                    error={"touched.bestDaysOfWeek.saturday" && "errors.bestDaysOfWeek.saturday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Saturday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.sunday" type="checkbox" value={"values.bestDaysOfWeek.sunday"} 
                    error={"touched.bestDaysOfWeek.sunday" && "errors.bestDaysOfWeek.sunday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Sunday
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="bestDaysOfWeek" component="div"/>  
        </CheckboxMainDiv>
      

        <CheckboxMainDiv>
          <label><p className="green">WHAT TIME OF DAY WORKS BEST FOR YOU? PLEASE CHECK ALL THAT APPLY.</p></label>

          <FormGroup check>
            <Label className="green" check>
              <Input name="bestTimesOfDay.morning" type="checkbox" value={"values.bestTimesOfDay.morning"} 
                    error={"touched.bestTimesOfDay.morning" && "errors.bestTimesOfDay.morning"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              MORNING (8AM - 11AM)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="green" check>
              <Input name="bestTimesOfDay.afternoon" type="checkbox" value={"values.bestTimesOfDay.afternoon"} 
                    error={"touched.bestTimesOfDay.afternoon" && "errors.bestTimesOfDay.afternoon"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              AFTERNOON (12PM - 3PM)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="green" check>
              <Input name="bestTimesOfDay.evening" type="checkbox" value={"values.bestTimesOfDay.evening"} 
                    error={"touched.bestTimesOfDay.evening" && "errors.bestTimesOfDay.evening"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              EVENING (4PM - LATER)
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="bestTimesOfDay" component="div"/>  
        </CheckboxMainDiv>
        
        <CheckboxMainDiv>
          <label><p className="blue">SELECT UP TO 5 DAYS AS POSSIBLE APPOINTMENT DAYS. I WILL LOOK AT MY SCHEDULE TO SEE IF I AM
            AVAILABLE ON YOUR SELECTED DAYS. THIS DOES NOT CONFIRM OR GUARANTEE AN APPOINTMENT. I WILL REACH OUT TO YOU WITH THE CONTACT
            INFO PROVIDED TO CONFIRM A DATE AND TIME.</p></label>
          <DayPicker_s
            name={"possibleAppointmentDates"} value={"values.possibleAppointmentDates"} 
            error={"touched.possibleAppointmentDates" && "errors.possibleAppointmentDates"} onBlur={handleBlur} onChange={handleChange}
            selectedDays={possibleAppointmentDates}
            onDayClick={handleDayClick} 
          />

            <ErrorMessage_Ext className="errorMessage" name="possibleAppointmentDates" component="div"/>  
        </CheckboxMainDiv>


        <CheckboxMainDiv>
          <label>
          <p className="purple">
            IS THIS YOUR FIRST TIME SEEING A PROFESSIONAL LOCTICIAN?
          </p>
          </label>

          <FormGroup check>
            <Label className="purple" check>
              <Input name="firstTimeSeeingLoctician" type="radio" value="Yes" checked={values.firstTimeSeeingLoctician === "Yes"}
                    error={"touched.firstTimeSeeingLoctician" && "errors.firstTimeSeeingLoctician"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              YES
            </Label>
          </FormGroup>
          
          <FormGroup check>
            <Label className="purple" check>
              <Input name="firstTimeSeeingLoctician" type="radio" value="No" checked={values.firstTimeSeeingLoctician === "No"}
                    error={"touched.firstTimeSeeingLoctician" && "errors.firstTimeSeeingLoctician"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NO
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="firstTimeSeeingLoctician" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label><p className="pink">TELL ME ABOUT YOUR DREADS! CHECK ALL OF THE FOLLOWING THAT APPLY TO YOUR DREADS.</p></label>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.allNaturalNeglect" type="checkbox" value={"values.dreadInfo.allNaturalNeglect"} 
                    error={"touched.dreadInfo.allNaturalNeglect" && "errors.dreadInfo.allNaturalNeglect"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE ALL NATURAL NEGLECT DREADS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.professionallyMade" type="checkbox" value={"values.dreadInfo.professionallyMade"} 
                    error={"touched.dreadInfo.professionallyMade" && "errors.dreadInfo.professionallyMade"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE PROFESSIONALLY MADE DREADS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.homeMade" type="checkbox" value={"values.dreadInfo.homeMade"} 
                    error={"touched.dreadInfo.homeMade" && "errors.dreadInfo.homeMade"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE HOMEMADE DREADS (BACKCOMB / TWIST & RIP)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.usedOrStillUsingWaxOrGel" type="checkbox" value={"values.dreadInfo.usedOrStillUsingWaxOrGel"} 
                    error={"touched.dreadInfo.usedOrStillUsingWaxOrGel" && "errors.dreadInfo.usedOrStillUsingWaxOrGel"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE USED OR AM STILL USING WAX AND OR GEL IN MY DREADS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.haveBuildUp" type="checkbox" value={"values.dreadInfo.haveBuildUp"} 
                    error={"touched.dreadInfo.haveBuildUp" && "errors.dreadInfo.haveBuildUp"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE BUILD UP IN MY DREADS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.rootsConnectedCantSeeScalp" type="checkbox" value={"values.dreadInfo.rootsConnectedCantSeeScalp"} 
                    error={"touched.dreadInfo.rootsConnectedCantSeeScalp" && "errors.dreadInfo.rootsConnectedCantSeeScalp"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              MY DREAD ROOTS ARE CONNECTED AND I CANNOT SE MY SCALP
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.lotOfLooseHair" type="checkbox" value={"values.dreadInfo.lotOfLooseHair"} 
                    error={"touched.dreadInfo.lotOfLooseHair" && "errors.dreadInfo.lotOfLooseHair"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              THERE IS A LOT OF LOOSE HAIR AROUND MY DREADS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.thinSpotsNeedRepair" type="checkbox" value={"values.dreadInfo.thinSpotsNeedRepair"} 
                    error={"touched.dreadInfo.thinSpotsNeedRepair" && "errors.dreadInfo.thinSpotsNeedRepair"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE THIN SPOTS THAT NEED REPAIR
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.needCombined" type="checkbox" value={"values.dreadInfo.needCombined"} 
                    error={"touched.dreadInfo.needCombined" && "errors.dreadInfo.needCombined"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE DREADS THAT NEED TO BE COMBINED
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.wantDreadsSplitInHalf" type="checkbox" value={"values.dreadInfo.wantDreadsSplitInHalf"} 
                    error={"touched.dreadInfo.wantDreadsSplitInHalf" && "errors.dreadInfo.wantDreadsSplitInHalf"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE A DREAD OR DREADS THAT I WANT TO SPLIT IN HALF
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="dreadInfo.loopsNeedFixed" type="checkbox" value={"values.dreadInfo.loopsNeedFixed"} 
                    error={"touched.dreadInfo.loopsNeedFixed" && "errors.dreadInfo.loopsNeedFixed"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE LOOPS THAT NEED TO BE FIXED
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="dreadInfo" component="div"/>  
        </CheckboxMainDiv>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="dreadAge"><p className="red">HOW OLD ARE YOUR DREADS?</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="dreadAge" />
            <ErrorMessage_Ext name="dreadAge" component="div" />  
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="haveHowManyDreads"><p className="orange">HOW MANY DREADS DO YOU HAVE?</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="haveHowManyDreads" />
            <ErrorMessage_Ext name="haveHowManyDreads" component="div" />  
          </Col>
        </Row>



        <CheckboxMainDiv>
          <label>
          <p className="green">
            CAN YOU TOUCH OR SEE YOUR SCALP?
          </p>
          </label>

          <FormGroup check>
            <Label className="green" check>
              <Input name="canYouTouchOrSeeScalp" type="radio" value="NO" checked={values.canYouTouchOrSeeScalp === "NO"}
                    error={"touched.canYouTouchOrSeeScalp" && "errors.canYouTouchOrSeeScalp"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NO
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="green" check>
              <Input name="canYouTouchOrSeeScalp" type="radio" value="YES" checked={values.canYouTouchOrSeeScalp === "YES"}
                    error={"touched.canYouTouchOrSeeScalp" && "errors.canYouTouchOrSeeScalp"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              YES
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="green" check>
              <Input name="canYouTouchOrSeeScalp" type="radio" value="NOT SURE" checked={values.canYouTouchOrSeeScalp === "NOT SURE"}
                    error={"touched.canYouTouchOrSeeScalp" && "errors.canYouTouchOrSeeScalp"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NOT SURE
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="canYouTouchOrSeeScalp" component="div"/>  
        </CheckboxMainDiv>



        <CheckboxMainDiv>
          <label>
          <p className="blue">
            WHAT IS THE HEALTH OF YOUR SCALP?
          </p>
          </label>

          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Normal" checked={values.scalpHealth === "Normal"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NORMAL
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Oily" checked={values.scalpHealth === "Oily"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              OILY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Dry" checked={values.scalpHealth === "Dry"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              DRY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Itchy" checked={values.scalpHealth === "Itchy"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              ITCHY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Flaky (dandruff)" checked={values.scalpHealth === "Flaky (dandruff)"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              FLAKY (DANDRUFF)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Psoriasis or other skin condition" checked={values.scalpHealth === "Psoriasis or other skin condition"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              PSORIASIS OR OTHER SKIN CONDITIONS (PLEASE SEE A DERMATOLOGIST BEFORE SEEING ME TO ENSURE YOUR SCALP IS HEALTHY ENOUGH FOR DREADS)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="scalpHealth" type="radio" value="Open sores/cuts" checked={values.scalpHealth === "Open sores/cuts"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              OPEN SORES/CUTS (PLEASE SEE A DERMATOLOGIST BEFORE SEEING ME TO ENSURE YOUR SCALP IS HEALTHY ENOUGH FOR DREADS)
            </Label>
          </FormGroup>


          <ErrorMessage_Ext className="errorMessage" name="scalpHealth" component="div"/>  
        </CheckboxMainDiv>
        
        <div>
          <h3 className="purple" style={{textAlign: "left", marginBottom: "20px"}}>UPLOAD SOME PHOTOS!</h3>

          <p className="purple" style={{margin: 0}}>PICTURE(S) OF YOU WITH YOUR CURRENT HAIR</p>
          <DropzoneDiv>
            <Dropzone onDrop={(acceptedFiles) => {

              let alreadyExists = false;
              values.currentHairPics.map((pic) => {
                if(pic.name === acceptedFiles[0].name) { alreadyExists = true; }
              })
              if(!alreadyExists) { setFieldValue("currentHairPics", values.currentHairPics.concat(acceptedFiles)); }

              }}>
              {({getRootProps, getInputProps, isDragActive}) => {
                return (
                  <DropzoneDiv
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p>Drop current hair pics here...</p> :
                        <p>Drag and drop, or click here to upload your current hair pics.</p>
                    }
                  </DropzoneDiv>
                )
              }}
            </Dropzone>
            {values.currentHairPics.length > 0 &&
              <ThumbsAside>
                <h3>Picture(s) of you with your current hair</h3>
                {values.currentHairPics.map((file, i) => (
                  Object.assign(file, { preview: URL.createObjectURL(file)}),

                  <ThumbDiv key={file.name}>
                    <ThumbInner>
                      <img src={file.preview} />
                    </ThumbInner>
                  </ThumbDiv>
                ))}
                <p className="red" style={{textAlign: "center", cursor: "pointer", margin: "0 auto", width: "150px"}} 
                  onClick={() => {setFieldValue("currentHairPics", []); }}><u>CLEAR IMAGES</u>
                </p>
              </ThumbsAside>
            }
          </DropzoneDiv>
        
        </div>


        <CheckboxMainDiv>
          <label>
          <p className="pink" style={{margin: "40px 15px 0 0"}}>
            DO YOU HAVE ANY ADDITIONAL INFORMATION YOU WOULD LIKE TO GIVE ME OR A QUESTION OR COMMENT? ADD IT HERE!
          </p>
          </label>

          <Input name="additionalInfo" type="textarea" alue={values.additionalInfo}
                error={touched.additionalInfo && errors.additionalInfo} onBlur={handleBlur} onChange={handleChange} />

          <ErrorMessage_Ext className="errorMessage" name="additionalInfo" component="div"/>  
        </CheckboxMainDiv>

        <StatusDiv><p>{status}</p></StatusDiv>

        <p style={{color: "black", textAlign: "center"}}>I strive to respond to all emails by the next business day.</p>
        <SubmitButton disabled={isSubmitting} type="submit"><p className="blue">Submit</p></SubmitButton>
        <hr style={{borderBottom: "2px solid black"}}/>

        <FormLoadingPopup onAfterOpen={props.onAfterOpen}
          formLoadingPopupClosing={props.formLoadingPopupClosing} formLoadingPopupOpen={props.formLoadingPopupOpen} />

      </FormikForm>

      );
    };

export default withRouter(withFormik({
  // Initial Values
  mapPropsToValues: (props) => (
    { 
      firstName: '', 
      lastName: '', 
      email: '', 
      phone: '', 
      originPlace: '',
      preferredContactMethod: { textMsg: false, call: false, email: false },
      parentalConsent: '',
      bestDaysOfWeek: { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false},
      bestTimesOfDay: { morning: false, afternoon: false, evening: false },
      possibleAppointmentDates: props.possibleAppointmentDates,
      firstTimeSeeingLoctician: '',
      dreadInfo: { allNaturalNeglect: false, professionallyMade: false, homeMade: false, usedOrStillUsingWaxOrGel: false, 
        haveBuildUp: false, rootsConnectedCantSeeScalp: false, lotOfLooseHair: false, thinSpotsNeedRepair: false, needCombined: false,
        wantDreadsSplitInHalf: false, loopsNeedFixed: false},
      dreadAge: '',
      haveHowManyDreads: '',
      canYouTouchOrSeeScalp: '',
      scalpHealth: '',
      currentHairPics: [],
      additionalInfo: '',
    }),

  // Validation
  validate: (values, props) => {
    //console.log("validating");
    //console.log(values);
  },
 
  // Submit
  handleSubmit: async (values, { setSubmitting, setStatus, resetForm, setFieldValue, props }) => {
    //setTimeout(async () => {
      setSubmitting(true);
      setStatus("Submitting... This may take a few seconds to minutes. Please be patient. Thank you!")
      // Opens the popup to say "Submitting..."
      props.formLoadingPopupOpening();
      let picLinkObject = await doS3Upload(values);
      await sendEmail(values, picLinkObject);
      //setFieldValue("possibleAppointmentDates", []);
      //setFieldValue("additionalInfo", "");
     // resetForm();
      props.formLoadingPopupClosing();
      setStatus("Form was successfully submitted. A confirmation email will be sent to you. Going back to home page...NOW!")
      await sleep(5000);
      window.scrollTo(0, 0);   
      props.history.push("/");
    //}, 1000);
  },

  displayName: "Dreadful Bliss Contact Form",
  validationSchema: (yup.object().shape({
    firstName: yup.string().required("Please enter your first name."),
    lastName: yup.string().required("Please enter your last name."),
    email: yup.string().email().required("Please enter your email address."),
    phone: yup.string()
      .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.')
      .required("Please enter your phone number."),
    originPlace: yup.string().required("Please enter the state or country you're traveling from."),
    preferredContactMethod: yup.object().shape({ textMsg: yup.boolean(), call: yup.boolean(), email: yup.boolean(), })
      .test('oneOrMoreIsTrue', "Please select one or more preferred contact methods.", function (value) {
      const { textMsg, call, email } = value;
      if(textMsg || call || email) return true
    }),
    parentalConsent: yup.string().required("Please select your method of consent."),
    bestDaysOfWeek: yup.object().shape({ monday: yup.boolean(), tuesday: yup.boolean(), wednesday: yup.boolean(), 
       thursday: yup.boolean(), friday: yup.boolean(), saturday: yup.boolean(), sunday: yup.boolean() })
      .test('oneOrMoreIsTrue', "Please select the best days of the week for you.", function (value) {
      const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = value;
      if(monday || tuesday || wednesday || thursday || friday || saturday || sunday) return true
    }),
    bestTimesOfDay: yup.object().shape({ morning: yup.boolean(), afternoon: yup.boolean(), evening: yup.boolean() })
      .test('oneOrMoreIsTrue', "Please select the best times of day for you.", function (value) {
      const { morning, afternoon, evening } = value;
      if(morning || afternoon || evening ) return true
    }),
    //possibleAppointmentDates: yup.array().of(yup.object().required("Please select at least one possible appointment date.")),
    firstTimeSeeingLoctician: yup.string().required("Please specify if this is your first time seeing a professional loctician."),
    dreadInfo: yup.object().shape({ allNaturalNeglect: yup.boolean(), professionallyMade: yup.boolean(), homeMade: yup.boolean(),
      usedOrStillUsingWaxOrGel: yup.boolean(), haveBuildUp: yup.boolean(), rootsConnectedCantSeeScalp: yup.boolean(), lotOfLooseHair: yup.boolean(),
      thinSpotsNeedRepair: yup.boolean(), needCombined: yup.boolean(), wantDreadsSplitInHalf: yup.boolean(), loopsNeedFixed: yup.boolean() })
      .test('oneOrMoreIsTrue', "Please give some information about your dreads.", function (value) {
      const { allNaturalNeglect, professionallyMade, homeMade, usedOrStillUsingWaxOrGel, haveBuildUp,
              rootsConnectedCantSeeScalp, lotOfLooseHair, thinSpotsNeedRepair, needCombined, wantDreadsSplitInHalf,
              loopsNeedFixed } = value;
      if(allNaturalNeglect || professionallyMade || homeMade || usedOrStillUsingWaxOrGel || haveBuildUp || rootsConnectedCantSeeScalp 
         || lotOfLooseHair || thinSpotsNeedRepair || needCombined || wantDreadsSplitInHalf || loopsNeedFixed ) return true
    }),

    dreadAge: yup.string().required("Please specify how old your dreads are."),
    haveHowManyDreads: yup.string().required("Please specify how many dreads you have."),
    canYouTouchOrSeeScalp: yup.string().required("Please specify if you can touch or see your scalp."),
    scalpHealth: yup.string().required("Please specify your scalp health.")
  }))

})(MaintenanceRepairForm));


const DayPicker_s = styled(DayPicker)`
  max-width: 290px;
  ${media.xs350` max-width: 100%; `}
  .DayPicker-Month { margin: 0; }
`;

const DropzoneDiv = styled.div`
    border: 1px dashed black;
    p {color: black;}
    cursor: pointer;
`;

const ThumbsAside = styled.aside`
    display: block;
    flexDirection: row;
    flexWrap: wrap;
    marginTop: 16px;
    cursor: default;
    h3 { margin: 0; }
`;

const ThumbDiv = styled.div`
    display: inline-flex;
    borderRadius: 2px;
    border: 1px solid #eaeaea;
    marginBottom: 8px;
    marginRight: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    boxSizing: 'border-box';
`;
const ThumbInner = styled.div`
    display: flex;
    minWidth: 0;
    overflow: hidden;

    img {
      display: block;
      width: auto;
      height: 100%;
    }
`;

const UploadPhotosDiv = styled.div`
  text-align: left;
  margin-bottom: 40px;
  p {margin-bottom: 10px; color: #4A90E2;}
`;

const CheckboxMainDiv = styled.div`
  text-align: left;
  margin-bottom: 40px;

  label { 
    padding: 0; 

    input {
      width: 30px;
    }
  }

`;

const StatusDiv = styled.div`
  p {
    color: #4BB543;
    font-weight: bold;
    text-align: center;
  }
`;


const ErrorMessage_Ext = styled(ErrorMessage)`
  color: #ff2323;
`;

const SubmitButton = styled(Button)`
  background-color: black!important;
  font-weight: bold!important;
  :hover { transform: translateY(3px); }

  p { margin: 0; }
`;
const LabelCol = styled(Col)`
  text-align: left;
  label { 
    margin: 0; 
    height: 100%;
    display: flex;
    align-items: center;
  } 
`;

const FormikForm = styled(Form)`
  label {
    p { margin: 0 15px 0 0; }
  }

  input, textarea {
    background: black!important;
    color: white!important;
    padding-left: 8px!important;
    width: 100%;
    ::placeholder { color: lightgray!important; }
    :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus 
      { transition: background-color 5000s ease-in-out 0s; -webkit-text-fill-color: white!important;}
    font-family: "Gochi Hand"!important;
    border-radius: 6px!important;
    border: none!important;
    outline: none;

    :focus {box-shadow: none; }
  }

  .row {
    margin: 0 0 15px 0;

    div { padding: 0!important; }
  }


`;

const DisplayRow = styled(Row)`

`;

const FluidContainer = styled(Container)`
  padding: 20px 0 20px 0px!important;
  background: white;
`;
const InternalContainer = styled(Container)`
  text-align: center;
  padding: 0 15px 0 15px!important;
  h1 { 
    color: black; 
    margin: 0 0 20px 0;
  }
`;