
import React from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, Input } from 'reactstrap';

import { Link } from 'react-router-dom'
import axios from 'axios';

import { Formik, Field, Form, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';

// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/contact
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/customextensions
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/maintenancerepair
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/newdreads

const sendEmail = (values) => {
  let URL = 'https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/contact';

  let emailData = {
    name: values.name,
    email: values.email,
    phone: values.phone,
    message: values.message
  };

  fetch(URL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify({emailData})
  })
  .then(function (response) {
    console.log('Response: ' + response.json());
  })
  .then(function (result) {
    console.log('Result: ' + result);
  })
  .catch(function(error) {
    console.log('Error: ' + error);
  });
}

const ContactForm = (props) => {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, status, setStatus, setFieldValue } = props;

    return (
      <FormikForm onSubmit={handleSubmit} autoComplete="on">

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="name"><h3 className="red">NAME:</h3></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="name" />
            <ErrorMessage_Ext name="name" component="div" />  
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="email"><h3 className="orange">EMAIL ADDRESS:</h3></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="email" />
            <ErrorMessage_Ext name="email" component="div" />
          </Col>
        </Row>


        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="phone"><h3 className="green">PHONE NUMBER:</h3></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="phone" />
            <ErrorMessage_Ext name="phone" component="div" />
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="message"><h3 className="blue">QUESTION OR COMMENT:</h3></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Input name="message" type="textarea" value={values.message}
                   error={touched.message && errors.message} onBlur={handleBlur} onChange={handleChange} />
            <ErrorMessage_Ext className="errorMessage" name="message" component="div"/>  
          </Col>
        </Row>
        
        <StatusDiv><p>{status}</p></StatusDiv>

        <p style={{color: "black", textAlign: "center"}}>I strive to respond to all emails within two business days.</p>
        <SubmitButton disabled={isSubmitting} type="submit"><p>Submit</p></SubmitButton>

      </FormikForm>
      );
    };


export default withFormik({
  // Initial Values
  mapPropsToValues: (props) => ({ name: '', email: '', phone: '', message: '' }),

  // Validation
  
  validate: values => {
    console.log("validating");
    console.log(values);
  },
 
  // Submit
  handleSubmit: (values, { setSubmitting, setStatus, resetForm }) => {
    setTimeout(() => {
      setSubmitting(true);
      sendEmail(values);
      resetForm();
      setStatus("Form was successfully submitted. Thank you!")
    }, 1000);
  },

  displayName: "Dreadful Bliss Contact Form",
  validationSchema: (yup.object().shape({
    name: yup.string().required("Please enter your name."),
    email: yup.string().email().required("Please enter your email address."),
    phone: yup.string()
      .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.')
      .required("Please enter your phone number."),
    message: yup.string().required("Please enter your question or comment.")
  }))

})(ContactForm);





const StatusDiv = styled.div`
  p {
    color: #4BB543;
    font-weight: bold;
    text-align: center;
  }
`;


const ErrorMessage_Ext = styled(ErrorMessage)`
  color: #ff2323;
`;

const SubmitButton = styled(Button)`
  background-color: #F8E71C!important;
  font-weight: bold!important;
  :hover { transform: translateY(3px); }

  p { margin: 0; color: black; }
`;
const LabelCol = styled(Col)`
  text-align: left;
  label { 
    margin: 0; 
    height: 100%;
    display: flex;
    align-items: center;
  } 
`;

const FormikForm = styled(Form)`
  label {
    h3 { margin: 0 15px 0 0; }
  }

  input, textarea {
    background: black!important;
    color: white!important;
    padding-left: 8px!important;
    width: 100%;
    ::placeholder { color: lightgray!important; }
    :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus 
      { transition: background-color 5000s ease-in-out 0s; -webkit-text-fill-color: white!important;}

    font-family: "Gochi Hand"!important;
    border-radius: 6px!important;
    border: none!important;
    outline: none;

    :focus {box-shadow: none; }
  }

  .row {
    margin: 0 0 15px 0;
  }
`;

const DisplayRow = styled(Row)`

`;

const FluidContainer = styled(Container)`
  padding: 20px 0 20px 0!important;
  background: white;
`;
const InternalContainer = styled(Container)`
  text-align: center;
  h1 { 
    color: black; 
    margin: 0 0 20px 0;
  }
`;