import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'


class BookAppointmentCustomExtensions extends Component {
  //static propTypes = {testProp: PropTypes.bool.isRequired}

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
      <FluidContainer className="p-0" fluid>
        <InternalContainer>
          
          {/*ExampleObject*/}
          <p>Hi from stateful example</p>
        </InternalContainer>
      </FluidContainer>
    );
  }
}

export default withRouter(BookAppointmentCustomExtensions);

const FluidContainer = styled(Container)`  `;
const InternalContainer = styled(Container)`  `;

//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/