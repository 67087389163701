import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';

import AWS from 'aws-sdk';

export default class GallerySection extends Component {
  constructor(props) {
    super(props);

    this.state = {
		galleryPics: []
    };
  }

  componentDidMount() {
  	this.getS3Pictures();
  }

getS3Pictures = async () => {
  const bucketName = 'dreadfulblissstorage';
  let galleryPics = [];

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:2c4b16a0-2c15-4ee2-8123-7e3bb2ef503b'
    })
  })
  
  let s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: bucketName, Prefix: (encodeURIComponent('gallery') +  '/' + encodeURIComponent('images') + '/')  }
  })
	
  let galleryPicsPromise = await
	  s3.listObjects( async (err, data) => { 
	  	let error = 'ERROR: ' + err;
	  	if(err) { console.log(error) }
	  	else { return data; }
	  }).promise()
	

  galleryPicsPromise.Contents.map( (pic, key) => {
  	if(key != 0) { galleryPics.push(galleryPicsPromise.$response.request.httpRequest.endpoint.href + '/' + bucketName + '/' + pic.Key)};
  })

  this.setState({ galleryPics });
}



  render() {
    const GalleryView = ( 
    <GalleryRow>
		{ this.state.galleryPics.map( (pic, key) => {
			return <GalleryCol xs="12" sm="6" lg="4" key={key}>
				<img src={pic} ></img>
			</GalleryCol>
		}
		)}
    </GalleryRow> );

    return (
	  <FluidContainer id="gallerySectionID" fluid>
	    <InternalContainer>
	      <h1>
	      	{/*
	      	<span className="red">D</span>
	      	<span className="orange">R</span>
	      	<span className="green">E</span>
	      	<span className="blue">A</span>
	      	<span className="purple">D</span>
	      	<span className="pink">F</span>
	      	<span className="red">U</span>
	      	<span className="orange">L</span>
	      	&nbsp;
	      	<span className="green">B</span>
	      	<span className="blue">L</span>
	      	<span className="purple">I</span>
	      	<span className="pink">S</span>
	      	<span className="red">S</span>
	      	&nbsp;
	      */}
	      	<span className="red">G</span>
	      	<span className="orange">A</span>
	      	<span className="green">L</span>
	      	<span className="blue">L</span>
	      	<span className="purple">E</span>
	      	<span className="pink">R</span>
	      	<span className="red">Y</span>

	      </h1>

		{GalleryView}

	    </InternalContainer>
	  </FluidContainer>

		);
	}
}

const GalleryRow = styled(Row)`
	
`;
const GalleryCol = styled(Col)`
	margin: 0 0 15px 0;

	img {
		height: auto;
		width: 100%;
		border-radius: 6px;
	
	

	}
`;

const DisplayRow = styled(Row)`

`;

const FluidContainer = styled(Container)`
	padding: 20px 0 20px 0!important;
	background: white;
`;
const InternalContainer = styled(Container)`
	text-align: center;
	h1 { 
		color: black; 
		margin: 0 0 20px 0;
	}
`;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/