
import React from 'react';
import { withRouter } from 'react-router-dom';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, Input, FormGroup, Label } from 'reactstrap';

import { Link } from 'react-router-dom'
import axios from 'axios';

import { Formik, Field, Form, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Dropzone from "react-dropzone";
import Thumb from "./Thumb";
import AWS from 'aws-sdk';
import moment from 'moment';
import { compose } from 'recompose';
import FormLoadingPopup from '../components/FormLoadingPopup.js';

// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/contact
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/customextensions
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/maintenancerepair
// https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/newdreads

// Initialize the Amazon Cognito credentials provider

const sleep = m => new Promise(r => setTimeout(r,m))

const doS3Upload = async (values) => {

  const picLinkObject = {
    currentHairPicsLinks: [],
    inspirationHairPicsLinks: [],
    additionalHairPicsLinks: []
  }

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:2c4b16a0-2c15-4ee2-8123-7e3bb2ef503b'
    })
  })
  
  let s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: 'dreadfulblissstorage'}
  })

  // CurrentHairPics
  let currentHairPicsLocations_Promises = values.currentHairPics.map(async (pic) => {
    let locationPromise = await s3.upload({
      Key: (encodeURIComponent('newdreads') + '/' + encodeURIComponent(values.firstName + '_' + values.lastName) + '/' 
        + encodeURIComponent('CurrentHairPics') + '/' + pic.name),
      Body: pic,
      ACL: 'public-read'
    }).promise();
    
    return locationPromise;
  })

  // InspirationHairPics
  let inspirationHairPicsLocations_Promises = values.inspirationHairPics.map(async (pic) => {
    let locationPromise = await s3.upload({
      Key: (encodeURIComponent('newdreads') + '/' + encodeURIComponent(values.firstName + '_' + values.lastName) 
        + '/' + encodeURIComponent('InspirationHairPics') + '/' + pic.name),
      Body: pic,
      ACL: 'public-read'
    }).promise();
    
    return locationPromise;
  })
  
  // AdditionalHairPics
  let additionalHairPicsLocations_Promises = values.additionalHairPics.map(async (pic) => {
    let locationPromise = await s3.upload({
      Key: (encodeURIComponent('newdreads') + '/' + encodeURIComponent(values.firstName + '_' + values.lastName) 
        + '/' + encodeURIComponent('AdditionalHairPics') + '/' + pic.name),
      Body: pic,
      ACL: 'public-read'
    }).promise();
    
    return locationPromise;
  })

  let currentHairPicsLinks_AllInfo =  await Promise.all(currentHairPicsLocations_Promises);
  let inspirationHairPicsLinks_AllInfo =  await Promise.all(inspirationHairPicsLocations_Promises);
  let additionalHairPicsLinks_AllInfo =  await Promise.all(additionalHairPicsLocations_Promises);

  picLinkObject.currentHairPicsLinks = currentHairPicsLinks_AllInfo.map( (picInfo) => { return picInfo.Location; })
  picLinkObject.inspirationHairPicsLinks = inspirationHairPicsLinks_AllInfo.map( (picInfo) => { return picInfo.Location; })
  picLinkObject.additionalHairPicsLinks = additionalHairPicsLinks_AllInfo.map( (picInfo) => { return picInfo.Location; })

  return picLinkObject;
}

const sendEmail = async (values, picLinkObject) => {
  let URLCasey = 'https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/newdreads';
  let URLCustomer = 'https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/newdreadsconfirmation';
  
  let name = values.firstName + ' ' + values.lastName;
  let possibleAppointmentDatesArray_NewFormat = [];
  let possibleAppointmentDatesStr = "";
  let currentHairPicsLinksStr = "";
  let inspirationHairPicsLinksStr = "";
  let additionalHairPicsLinksStr = "";
  let preferredContactMethodString = '';
  let preferredContactMethodArray = [];
  let bestDaysOfWeekArray = [];
  let bestDaysOfWeekString = '';
  let bestTimesOfDayArray = [];
  let bestTimesOfDayString = '';
  
  
  // Make all checkbox "select all that apply" fields be a pretty string for the email.

  Object.keys(values.preferredContactMethod).forEach(key => {
    const keyValue = values.preferredContactMethod[key];

    if(keyValue === true) {
      preferredContactMethodArray.push(key);
    }
  })
  preferredContactMethodString = preferredContactMethodArray.join(", ");

  Object.keys(values.bestDaysOfWeek).forEach(key => {
    const keyValue = values.bestDaysOfWeek[key];

    if(keyValue === true) {
      bestDaysOfWeekArray.push(key);
    }
  })
  bestDaysOfWeekString = bestDaysOfWeekArray.join(", ");

  Object.keys(values.bestTimesOfDay).forEach(key => {
    const keyValue = values.bestTimesOfDay[key];

    if(keyValue === true) {
      bestTimesOfDayArray.push(key);
    }
  })
  bestTimesOfDayString = bestTimesOfDayArray.join(", ");

  values.possibleAppointmentDates.map((date) => {
    let newDate = moment(date.toString()).format("MM/DD/YYYY");
    possibleAppointmentDatesArray_NewFormat.push(newDate);
  })

  // End of Make all checkbox "select all that apply" fields be a pretty string for the email section.
  
  possibleAppointmentDatesArray_NewFormat.length > 0 ?  possibleAppointmentDatesStr = possibleAppointmentDatesArray_NewFormat.join(", ") : possibleAppointmentDatesStr = ""
  picLinkObject.currentHairPicsLinks.length > 0 ?  currentHairPicsLinksStr = picLinkObject.currentHairPicsLinks.join(" ||||| ") : currentHairPicsLinksStr = ""
  picLinkObject.inspirationHairPicsLinks.length > 0 ?  inspirationHairPicsLinksStr = picLinkObject.inspirationHairPicsLinks.join(" ||||| ") : inspirationHairPicsLinksStr = ""
  picLinkObject.additionalHairPicsLinks.length > 0 ?  additionalHairPicsLinksStr = picLinkObject.additionalHairPicsLinks.join(" ||||| ") : additionalHairPicsLinksStr = ""
  
  let emailData = {
    name: name,
    email: values.email,
    phone: values.phone,
    originPlace: values.originPlace,
    preferredContactMethod: preferredContactMethodString,
    parentalConsent: values.parentalConsent,
    bestDaysOfWeek: bestDaysOfWeekString,
    bestTimesOfDay: bestTimesOfDayString,
    possibleAppointmentDates: possibleAppointmentDatesStr,
    firstTimeSeeingLoctician: values.firstTimeSeeingLoctician,
    whatServices: values.whatServices,
    howManyDreadlocks: values.howManyDreadlocks,
    hairThickness: values.hairThickness,
    hairType: values.hairType,
    hairLength: values.hairLength,
    dreadlockSizeDesired: values.dreadlockSizeDesired,
    howDoYouKeepHair: values.howDoYouKeepHair,
    scalpHealth: values.scalpHealth,
    currentHairPicsLinks: currentHairPicsLinksStr,
    inspirationHairPicsLinks: inspirationHairPicsLinksStr,
    additionalHairPicsLinks: additionalHairPicsLinksStr,
    additionalInfo: values.additionalInfo
  };
  

  // Send email to Casey
  fetch(URLCasey, {
    method: 'POST',
    
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify({emailData})
  })
  .then(function (response) {
    //console.log('Response: ' + JSON.stringify({emailData}));
  })
  .then(function (result) {
    //console.log('Result: ' + result);
  })
  .catch(function(error) {
    //console.log('Error: ' + error);
  });

  // Send confirmation email to customer

  fetch(URLCustomer, {
    method: 'POST',
    
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify({emailData})
  })
  .then(function (response) {
    //console.log('Response: ' + JSON.stringify({emailData}));
  })
  .then(function (result) {
    //console.log('Result: ' + result);
  })
  .catch(function(error) {
    //console.log('Error: ' + error);
  });
}


const NewDreadsForm = (props) => {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, 
    status, setStatus, setFieldValue, possibleAppointmentDates, handleDayClick, goToHome, 
    onAfterOpen, formLoadingPopupOpening, formLoadingPopupOpen, formLoadingPopupClosing } = props;
    return (
      <FormikForm onSubmit={handleSubmit} autoComplete="on">

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="firstName"><p className="red">FIRST NAME</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="firstName" />
            <ErrorMessage_Ext name="firstName" component="div" />  
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="lastName"><p className="orange">LAST NAME</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="lastName" />
            <ErrorMessage_Ext name="lastName" component="div" />  
          </Col>
        </Row>

        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="email"><p className="green">EMAIL ADDRESS</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="email" />
            <ErrorMessage_Ext name="email" component="div" />
          </Col>
        </Row>


        <Row>
          <LabelCol xs="12" sm="4">
            <label htmlFor="phone"><p className="blue">PHONE NUMBER</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="phone" />
            <ErrorMessage_Ext name="phone" component="div" />
          </Col>
        </Row>

        <Row style={{marginBottom: "40px"}}>
          <LabelCol xs="12" sm="4">
            <label htmlFor="originPlace"><p className="purple">STATE OR COUNTRY YOU WILL BE TRAVELING FROM</p></label>
          </LabelCol>
          <Col xs="12" sm="8">
            <Field name="originPlace" />
            <ErrorMessage_Ext name="originPlace" component="div" />
          </Col>
        </Row>
        
        <CheckboxMainDiv>
          <label><p className="pink">HOW WOULD YOU PREFER I CONTACT YOU? PLEASE CHECK ALL THAT APPLY.</p></label>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="preferredContactMethod.textMsg" type="checkbox" value={"values.preferredContactMethod.textMsg"} 
                    error={"touched.preferredContactMethod.textMsg" && "errors.preferredContactMethod.textMsg"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              TEXT ME!
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink"  check>
              <Input name="preferredContactMethod.call" type="checkbox" value={"values.preferredContactMethod.call"} 
                    error={"touched.preferredContactMethod.call" && "errors.preferredContactMethod.call"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              CALL ME!
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink"  check>
              <Input name="preferredContactMethod.email" type="checkbox" value={"values.preferredContactMethod.email"} 
                    error={"touched.preferredContactMethod.email" && "errors.preferredContactMethod.email"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              EMAIL ME!
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="preferredContactMethod" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="red">
            IF YOU ARE UNDER THE AGE OF 18, DO YOU HAVE PARENTAL CONSENT? IF YOU ARE UNDER 18, CHOOSE YES, AND YOUR LEGAL GUARDIAN MUST EITHER
            ACCOMPANY YOU, OR YOU MUST FILL OUT A MINOR CONSENT FORM, SIGNED BY YOUR LEGAL GUARDIAN IF YOU ARE COMING ALONE. 
            I WILL EMAIL YOU THE FORM IF YOU CHOOSE YES.
          </p>
          </label>

          <FormGroup check>
            <Label className="red" check>
              <Input name="parentalConsent" type="radio" value="My guardian consents" checked={values.parentalConsent === "My guardian consents"}
                    error={"touched.parentalConsent" && "errors.parentalConsent"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              YES, AND MY LEGAL GUARDIAN CONSENTS
            </Label>
          </FormGroup>
          
          <FormGroup check>
            <Label className="red" check>
              <Input name="parentalConsent" type="radio" value="I am 18" checked={values.parentalConsent === "I am 18"}
                    error={"touched.parentalConsent" && "errors.parentalConsent"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I AM 18 OR OLDER
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="parentalConsent" component="div"/>  
        </CheckboxMainDiv>
        
        <CheckboxMainDiv>
          <label>
          <p className="orange">
            WHAT DAYS OF THE WEEK ARE BEST FOR YOU? MOST APPOINTMENTS ARE AVAILABLE ON WEEKDAYS, BUT I TAKE WEEKEND APPOINTMENTS 
            IF THAT IS ALL THAT WORKS FOR YOU. PLEASE CHECK ALL THAT APPLY.
          </p>
          </label>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.monday" type="checkbox" value={"values.bestDaysOfWeek.monday"} 
                    error={"touched.bestDaysOfWeek.monday" && "errors.bestDaysOfWeek.monday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Monday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.tuesday" type="checkbox" value={"values.bestDaysOfWeek.tuesday"} 
                    error={"touched.bestDaysOfWeek.tuesday" && "errors.bestDaysOfWeek.tuesday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Tuesday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.wednesday" type="checkbox" value={"values.bestDaysOfWeek.wednesday"} 
                    error={"touched.bestDaysOfWeek.wednesday" && "errors.bestDaysOfWeek.wednesday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Wednesday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.thursday" type="checkbox" value={"values.bestDaysOfWeek.thursday"} 
                    error={"touched.bestDaysOfWeek.thursday" && "errors.bestDaysOfWeek.thursday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Thursday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.friday" type="checkbox" value={"values.bestDaysOfWeek.friday"} 
                    error={"touched.bestDaysOfWeek.friday" && "errors.bestDaysOfWeek.friday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Friday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.saturday" type="checkbox" value={"values.bestDaysOfWeek.saturday"} 
                    error={"touched.bestDaysOfWeek.saturday" && "errors.bestDaysOfWeek.saturday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Saturday
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="bestDaysOfWeek.sunday" type="checkbox" value={"values.bestDaysOfWeek.sunday"} 
                    error={"touched.bestDaysOfWeek.sunday" && "errors.bestDaysOfWeek.sunday"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              Sunday
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="bestDaysOfWeek" component="div"/>  
        </CheckboxMainDiv>
      

        <CheckboxMainDiv>
          <label><p className="green">WHAT TIME OF DAY WORKS BEST FOR YOU? PLEASE CHECK ALL THAT APPLY.</p></label>

          <FormGroup check>
            <Label className="green" check>
              <Input name="bestTimesOfDay.morning" type="checkbox" value={"values.bestTimesOfDay.morning"} 
                    error={"touched.bestTimesOfDay.morning" && "errors.bestTimesOfDay.morning"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              MORNING (8AM - 11AM)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="green" check>
              <Input name="bestTimesOfDay.afternoon" type="checkbox" value={"values.bestTimesOfDay.afternoon"} 
                    error={"touched.bestTimesOfDay.afternoon" && "errors.bestTimesOfDay.afternoon"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              AFTERNOON (12PM - 3PM)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="green" check>
              <Input name="bestTimesOfDay.evening" type="checkbox" value={"values.bestTimesOfDay.evening"} 
                    error={"touched.bestTimesOfDay.evening" && "errors.bestTimesOfDay.evening"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              EVENING (4PM - LATER)
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="bestTimesOfDay" component="div"/>  
        </CheckboxMainDiv>
        
        <CheckboxMainDiv>
          <label><p className="blue">SELECT UP TO 5 DAYS AS POSSIBLE APPOINTMENT DAYS. I WILL LOOK AT MY SCHEDULE TO SEE IF I AM
            AVAILABLE ON YOUR SELECTED DAYS. THIS DOES NOT CONFIRM OR GUARANTEE AN APPOINTMENT. I WILL REACH OUT TO YOU WITH THE CONTACT
            INFO PROVIDED TO CONFIRM A DATE AND TIME.</p></label>
          <DayPicker_s
            name={"possibleAppointmentDates"} value={"values.possibleAppointmentDates"} 
            error={"touched.possibleAppointmentDates" && "errors.possibleAppointmentDates"} onBlur={handleBlur} onChange={handleChange}
            selectedDays={possibleAppointmentDates}
            onDayClick={handleDayClick} 
          />

            <ErrorMessage_Ext className="errorMessage" name="possibleAppointmentDates" component="div"/>  
        </CheckboxMainDiv>


        <CheckboxMainDiv>
          <label>
          <p className="purple">
            IS THIS YOUR FIRST TIME SEEING A PROFESSIONAL LOCTICIAN?
          </p>
          </label>

          <FormGroup check>
            <Label className="purple" check>
              <Input name="firstTimeSeeingLoctician" type="radio" value="Yes" checked={values.firstTimeSeeingLoctician === "Yes"}
                    error={"touched.firstTimeSeeingLoctician" && "errors.firstTimeSeeingLoctician"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              YES
            </Label>
          </FormGroup>
          
          <FormGroup check>
            <Label className="purple" check>
              <Input name="firstTimeSeeingLoctician" type="radio" value="No" checked={values.firstTimeSeeingLoctician === "No"}
                    error={"touched.firstTimeSeeingLoctician" && "errors.firstTimeSeeingLoctician"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NO
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="firstTimeSeeingLoctician" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="pink">
            WHAT SERVICES ARE YOU LOOKING FOR?
          </p>
          </label>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="whatServices" type="radio" value="New Dreads, No Extensions" checked={values.whatServices === "New Dreads, No Extensions"}
                    error={"touched.whatServices" && "errors.whatServices"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NEW DREADLOCKS, NO EXTENSIONS
            </Label>
          </FormGroup>
          
          <FormGroup check>
            <Label className="pink" check>
              <Input name="whatServices" type="radio" value="New Dreads, Synthetic Extensions" checked={values.whatServices === "New Dreads, Synthetic Extensions"}
                    error={"touched.whatServices" && "errors.whatServices"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NEW DREADLOCKS WITH SYNTHETIC EXTENSIONS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="whatServices" type="radio" value="New Dreads, Human Hair Extensions" checked={values.whatServices === "New Dreads, Human Hair Extensions"}
                    error={"touched.whatServices" && "errors.whatServices"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NEW DREADLOCKS WITH HUMAN HAIR EXTENSIONS
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="whatServices" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="red">
            FOR STARTING NEW DREADLOCKS, HOW MANY DREADLOCKS DO YOU WANT? NUMBERS OF DREADS WILL CHANGE BASED ON HAIR TEXTURE AND HAIR THICKNESS.
          </p>
          </label>

          <FormGroup check>
            <Label className="red" check>
              <Input name="howManyDreadlocks" type="radio" value="Just a few (1-9)" checked={values.howManyDreadlocks === "Just a few (1-9)"}
                    error={"touched.howManyDreadlocks" && "errors.howManyDreadlocks"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              JUST A FEW (1-9)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="red" check>
              <Input name="howManyDreadlocks" type="radio" value="Half head (10-30)" checked={values.howManyDreadlocks === "Half head (10-30)"}
                    error={"touched.howManyDreadlocks" && "errors.howManyDreadlocks"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              HALF HEAD (10-30)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="red" check>
              <Input name="howManyDreadlocks" type="radio" value="Whole head (40-60)" checked={values.howManyDreadlocks === "Whole head (40-60)"}
                    error={"touched.howManyDreadlocks" && "errors.howManyDreadlocks"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              WHOLE HEAD (40-60)
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="red" check>
              <Input name="howManyDreadlocks" type="radio" value="Whole head, but leave bangs or loose hairs" 
                    checked={values.howManyDreadlocks === "Whole head, but leave bangs or loose hairs" }
                    error={"touched.howManyDreadlocks" && "errors.howManyDreadlocks"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              WHOLE HEAD, BUT LEAVE THE BANGS OR LOOSE HAIRS
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="red" check>
              <Input name="howManyDreadlocks" type="radio" value="I have some already but would like more" 
                    checked={values.howManyDreadlocks === "I have some already but would like more" }
                    error={"touched.howManyDreadlocks" && "errors.howManyDreadlocks"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              I HAVE SOME ALREADY, BUT WOULD LIKE MORE ADDED
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="howManyDreadlocks" component="div"/>  

        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="orange">
            HOW THICK IS YOUR HAIR
          </p>
          </label>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="hairThickness" type="radio" value="Fine" checked={values.hairThickness === "Fine"}
                    error={"touched.hairThickness" && "errors.hairThickness"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              FINE
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="hairThickness" type="radio" value="Normal" checked={values.hairThickness === "Normal"}
                    error={"touched.hairThickness" && "errors.hairThickness"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NORMAL
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="orange" check>
              <Input name="hairThickness" type="radio" value="Thick" checked={values.hairThickness === "Thick"}
                    error={"touched.hairThickness" && "errors.hairThickness"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              THICK
            </Label>
          </FormGroup>


          <ErrorMessage_Ext className="errorMessage" name="hairThickness" component="div"/>  
        </CheckboxMainDiv>


        <CheckboxMainDiv>
          <label>
          <p className="green">
            WHAT IS YOUR HAIR TYPE?
          </p>
          </label>

          <FormGroup check>
            <Label className="green" check>
              <Input name="hairType" type="radio" value="Straight" checked={values.hairType === "Straight"}
                    error={"touched.hairType" && "errors.hairType"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              STRAIGHT
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="green" check>
              <Input name="hairType" type="radio" value="Wavy" checked={values.hairType === "Wavy"}
                    error={"touched.hairType" && "errors.hairType"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              WAVY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="green" check>
              <Input name="hairType" type="radio" value="Curly" checked={values.hairType === "Curly"}
                    error={"touched.hairType" && "errors.hairType"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              CURLY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="green" check>
              <Input name="hairType" type="radio" value="Kinky" checked={values.hairType === "Kinky"}
                    error={"touched.hairType" && "errors.hairType"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              KINKY
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="hairType" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="blue">
            HOW LONG IS YOUR HAIR? YOUR HAIR NEEDS TO BE A MINIMUM OF 2 INCHES LONG TO START DREADLOCKS AND AT LEAST
            3 INCHES LONG TO ADD DREADLOCK EXTENSIONS
          </p>
          </label>

          <FormGroup check>
            <Label className="blue" check>
              <Input name="hairLength" type="radio" value="Short" checked={values.hairLength === "Short"}
                    error={"touched.hairLength" && "errors.hairLength"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              SHORT
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="hairLength" type="radio" value="Above shoulder length" checked={values.hairLength === "Above shoulder length"}
                    error={"touched.hairLength" && "errors.hairLength"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              ABOVE SHOULDER LENGTH
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="hairLength" type="radio" value="Upper back" checked={values.hairLength === "Upper back"}
                    error={"touched.hairLength" && "errors.hairLength"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              UPPER BACK
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="hairLength" type="radio" value="Mid back" checked={values.hairLength === "Mid back"}
                    error={"touched.hairLength" && "errors.hairLength"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              MID BACK
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="hairLength" type="radio" value="Lower back" checked={values.hairLength === "Lower back"}
                    error={"touched.hairLength" && "errors.hairLength"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              LOWER BACK
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="blue" check>
              <Input name="hairLength" type="radio" value="Longer" checked={values.hairLength === "Longer"}
                    error={"touched.hairLength" && "errors.hairLength"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              LONGER
            </Label>
          </FormGroup>
          <ErrorMessage_Ext className="errorMessage" name="hairLength" component="div"/>  
        </CheckboxMainDiv>


        <CheckboxMainDiv>
          <label>
          <p className="purple">
            WHAT SIZE DREADLOCKS DO YOU WANT?
          </p>
          </label>

          <FormGroup check>
            <Label className="purple" check>
              <Input name="dreadlockSizeDesired" type="radio" value="Pencil (skinny)" checked={values.dreadlockSizeDesired === "Pencil (skinny)"}
                    error={"touched.dreadlockSizeDesired" && "errors.dreadlockSizeDesired"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              PENCIL (SKINNY)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="purple" check>
              <Input name="dreadlockSizeDesired" type="radio" value="Pen (average)" checked={values.dreadlockSizeDesired === "Pen (average)"}
                    error={"touched.dreadlockSizeDesired" && "errors.dreadlockSizeDesired"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              PEN (AVERAGE)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="purple" check>
              <Input name="dreadlockSizeDesired" type="radio" value="Sharpie (thick)" checked={values.dreadlockSizeDesired === "Sharpie (thick)"}
                    error={"touched.dreadlockSizeDesired" && "errors.dreadlockSizeDesired"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              SHARPIE (THICK)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="purple" check>
              <Input name="dreadlockSizeDesired" type="radio" value="Highlighter (large)" checked={values.dreadlockSizeDesired === "Highlighter (large)"}
                    error={"touched.dreadlockSizeDesired" && "errors.dreadlockSizeDesired"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              HIGHLIGHTER (LARGE)
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="dreadlockSizeDesired" component="div"/>  
        </CheckboxMainDiv>

        <CheckboxMainDiv>
          <label>
          <p className="pink">
            HOW DO YOU KEEP YOUR HAIR?
          </p>
          </label>

          <FormGroup check>
            <Label className="pink" check>
              <Input name="howDoYouKeepHair" type="radio" value="All natural" checked={values.howDoYouKeepHair === "All natural"}
                    error={"touched.howDoYouKeepHair" && "errors.howDoYouKeepHair"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              ALL NATURAL
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="pink" check>
              <Input name="howDoYouKeepHair" type="radio" value="Dyed" checked={values.howDoYouKeepHair === "Dyed"}
                    error={"touched.howDoYouKeepHair" && "errors.howDoYouKeepHair"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              DYED
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="pink" check>
              <Input name="howDoYouKeepHair" type="radio" value="Bleached" checked={values.howDoYouKeepHair === "Bleached"}
                    error={"touched.howDoYouKeepHair" && "errors.howDoYouKeepHair"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              BLEACHED
            </Label>
          </FormGroup>

          <ErrorMessage_Ext className="errorMessage" name="howDoYouKeepHair" component="div"/>  
        </CheckboxMainDiv>


        <CheckboxMainDiv>
          <label>
          <p className="red">
            WHAT IS THE HEALTH OF YOUR SCALP?
          </p>
          </label>

          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Normal" checked={values.scalpHealth === "Normal"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              NORMAL
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Oily" checked={values.scalpHealth === "Oily"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              OILY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Dry" checked={values.scalpHealth === "Dry"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              DRY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Itchy" checked={values.scalpHealth === "Itchy"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              ITCHY
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Flaky (dandruff)" checked={values.scalpHealth === "Flaky (dandruff)"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              FLAKY (DANDRUFF)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Psoriasis or other skin condition" checked={values.scalpHealth === "Psoriasis or other skin condition"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              PSORIASIS OR OTHER SKIN CONDITIONS (PLEASE SEE A DERMATOLOGIST BEFORE SEEING ME TO ENSURE YOUR SCALP IS HEALTHY ENOUGH FOR DREADS)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="red" check>
              <Input name="scalpHealth" type="radio" value="Open sores/cuts" checked={values.scalpHealth === "Open sores/cuts"}
                    error={"touched.scalpHealth" && "errors.scalpHealth"} onBlur={handleBlur} onChange={handleChange} /> {' '}
              OPEN SORES/CUTS (PLEASE SEE A DERMATOLOGIST BEFORE SEEING ME TO ENSURE YOUR SCALP IS HEALTHY ENOUGH FOR DREADS)
            </Label>
          </FormGroup>


          <ErrorMessage_Ext className="errorMessage" name="scalpHealth" component="div"/>  
        </CheckboxMainDiv>
        
        <div>
          <h3 className="orange" style={{textAlign: "left", marginBottom: "20px"}}>UPLOAD SOME PHOTOS!</h3>
  
          <p className="orange" style={{margin: 0}}>PICTURE(S) OF YOU WITH YOUR CURRENT HAIR</p>
          <DropzoneDiv>
            <Dropzone onDrop={(acceptedFiles) => {

              let alreadyExists = false;
              values.currentHairPics.map((pic) => {
                if(pic.name === acceptedFiles[0].name) { alreadyExists = true; }
              })
              if(!alreadyExists) { setFieldValue("currentHairPics", values.currentHairPics.concat(acceptedFiles)); }

              }}>
              {({getRootProps, getInputProps, isDragActive}) => {
                return (
                  <DropzoneDiv
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p>Drop current hair pics here...</p> :
                        <p>Drag and drop, or click here to upload your current hair pics.</p>
                    }
                  </DropzoneDiv>
                )
              }}
            </Dropzone>
            {values.currentHairPics.length > 0 &&
              <ThumbsAside>
                <h3>Picture(s) of you with your current hair</h3>
                {values.currentHairPics.map((file, i) => (
                  Object.assign(file, { preview: URL.createObjectURL(file)}),

                  <ThumbDiv key={file.name}>
                    <ThumbInner>
                      <img src={file.preview} />
                    </ThumbInner>
                  </ThumbDiv>
                ))}
                <p className="red" style={{textAlign: "center", cursor: "pointer", margin: "0 auto", width: "150px"}} 
                  onClick={() => {setFieldValue("currentHairPics", []); }}><u>CLEAR IMAGES</u>
                </p>
              </ThumbsAside>
            }
          </DropzoneDiv>
        

          <p className="orange" style={{margin: "15px 0 0 0"}}>INSPIRATION PIC OF THE DREADOCKS YOU WOULD LOVE TO HAVE</p>
          <DropzoneDiv>
            <Dropzone onDrop={(acceptedFiles) => {
              
              let alreadyExists = false;
              values.inspirationHairPics.map((pic) => {
                if(pic.name === acceptedFiles[0].name) { alreadyExists = true; }
              })
              if(!alreadyExists) { setFieldValue("inspirationHairPics", values.inspirationHairPics.concat(acceptedFiles)); }

              }}>
              {({getRootProps, getInputProps, isDragActive}) => {
                return (
                  <DropzoneDiv
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p>Drop dreadlocks inspiration pics here...</p> :
                        <p>Drag and drop, or click here to upload your dreadlocks inspiration pics.</p>
                    }
                  </DropzoneDiv>
                )
              }}
            </Dropzone>
            {values.inspirationHairPics.length > 0 &&
              <ThumbsAside>
                <h3>Picture(s) of your dreadlocks inspiration</h3>
                {values.inspirationHairPics.map((file, i) => (
                  Object.assign(file, { preview: URL.createObjectURL(file)}),

                  <ThumbDiv key={file.name}>
                    <ThumbInner>
                      <img src={file.preview} />
                    </ThumbInner>
                  </ThumbDiv>
                ))}
                <p className="red" style={{textAlign: "center", cursor: "pointer", margin: "0 auto", width: "150px"}} 
                  onClick={() => {setFieldValue("inspirationHairPics", []); }}><u>CLEAR IMAGES</u>
                </p>
              </ThumbsAside>
            }
          </DropzoneDiv>


          <p className="orange" style={{margin: "15px 0 0 0"}}>ADDITIONAL PHOTOS</p>
          <DropzoneDiv>
            <Dropzone onDrop={(acceptedFiles) => {
              
              let alreadyExists = false;
              values.additionalHairPics.map((pic) => {
                if(pic.name === acceptedFiles[0].name) { alreadyExists = true; }
              })
              if(!alreadyExists) { setFieldValue("additionalHairPics", values.additionalHairPics.concat(acceptedFiles)); }

              }}>
              {({getRootProps, getInputProps, isDragActive}) => {
                return (
                  <DropzoneDiv
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p>Drop additional pics here...</p> :
                        <p>Drag and drop, or click here to upload your additional pics.</p>
                    }
                  </DropzoneDiv>
                )
              }}
            </Dropzone>
            {values.additionalHairPics.length > 0 &&
              <ThumbsAside>
                <h3>Additional picture(s)</h3>
                {values.additionalHairPics.map((file, i) => (
                  Object.assign(file, { preview: URL.createObjectURL(file)}),

                  <ThumbDiv key={file.name}>
                    <ThumbInner>
                      <img src={file.preview} />
                    </ThumbInner>
                  </ThumbDiv>
                ))}
                <p className="red" style={{textAlign: "center", cursor: "pointer", margin: "0 auto", width: "150px"}} 
                  onClick={() => {setFieldValue("additionalHairPics", []); }}><u>CLEAR IMAGES</u>
                </p>
              </ThumbsAside>
            }
          </DropzoneDiv>
        </div>


        <CheckboxMainDiv>
          <label>
          <p className="green" style={{margin: "40px 15px 0 0"}}>
            DO YOU HAVE ANY ADDITIONAL INFORMATION YOU WOULD LIKE TO GIVE ME OR A QUESTION OR COMMENT? ADD IT HERE!
          </p>
          </label>

          <Input name="additionalInfo" type="textarea" alue={values.additionalInfo}
                error={touched.additionalInfo && errors.additionalInfo} onBlur={handleBlur} onChange={handleChange} />

          <ErrorMessage_Ext className="errorMessage" name="additionalInfo" component="div"/>  
        </CheckboxMainDiv>

        <StatusDiv><p>{status}</p></StatusDiv>

        <p style={{color: "black", textAlign: "center"}}>I strive to respond to all emails by the next business day.</p>
        <SubmitButton disabled={isSubmitting} type="submit"><p className="blue">Submit</p></SubmitButton>
        <hr style={{borderBottom: "2px solid black"}}/>

        <FormLoadingPopup onAfterOpen={props.onAfterOpen}
          formLoadingPopupClosing={props.formLoadingPopupClosing} formLoadingPopupOpen={props.formLoadingPopupOpen} />
      </FormikForm>

      );
    };



export default withRouter(withFormik({
  // Initial Values
  mapPropsToValues: (props) => (
    { 
      firstName: '', 
      lastName: '', 
      email: '', 
      phone: '', 
      originPlace: '',
      preferredContactMethod: { textMsg: false, call: false, email: false },
      parentalConsent: '',
      bestDaysOfWeek: { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false},
      bestTimesOfDay: { morning: false, afternoon: false, evening: false },
      possibleAppointmentDates: props.possibleAppointmentDates,
      firstTimeSeeingLoctician: '',
      whatServices: '',
      howManyDreadlocks: '',
      hairThickness: '',
      hairType: '',
      hairLength: '',
      dreadlockSizeDesired: '',
      howDoYouKeepHair: '',
      scalpHealth: '',
      currentHairPics: [],
      inspirationHairPics: [],
      additionalHairPics: [],
      additionalInfo: '',
    }),

  // Validation
  validate: (values, props) =>  {
    //props.formLoadingPopupOpening();
    //console.log("validating");
    //console.log(values);
  },
 
  // Submit
  handleSubmit: async (values, { setSubmitting, setStatus, resetForm, setFieldValue, props }) => {
   // setTimeout(() => {
      setSubmitting(true);
      setStatus("Submitting... This may take a few seconds to minutes. Please be patient. Thank you!")
       // Opens the popup to say "Submitting..."
      props.formLoadingPopupOpening();
      let picLinkObject = await doS3Upload(values);
      await sendEmail(values, picLinkObject);
      //setFieldValue("possibleAppointmentDates", []);
      // setFieldValue("additionalInfo", "");
      //resetForm();
      props.formLoadingPopupClosing();
      setStatus("Form was successfully submitted. A confirmation email will be sent to you. Going back to home page...NOW!")
      await sleep(5000);
      window.scrollTo(0, 0);   
      props.history.push("/");
    //}, 1000);
  },

  displayName: "Dreadful Bliss Contact Form",
  validationSchema: (yup.object().shape({
    firstName: yup.string().required("Please enter your first name."),
    lastName: yup.string().required("Please enter your last name."),
    email: yup.string().email().required("Please enter your email address."),
    phone: yup.string()
      .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.')
      .required("Please enter your phone number."),
    originPlace: yup.string().required("Please enter the state or country you're traveling from."),
    preferredContactMethod: yup.object().shape({ textMsg: yup.boolean(), call: yup.boolean(), email: yup.boolean(), })
      .test('oneOrMoreIsTrue', "Please select one or more preferred contact methods.", function (value) {
      const { textMsg, call, email } = value;
      if(textMsg || call || email) return true
    }),
    parentalConsent: yup.string().required("Please select your method of consent."),
    bestDaysOfWeek: yup.object().shape({ monday: yup.boolean(), tuesday: yup.boolean(), wednesday: yup.boolean(), 
       thursday: yup.boolean(), friday: yup.boolean(), saturday: yup.boolean(), sunday: yup.boolean() })
      .test('oneOrMoreIsTrue', "Please select the best days of the week for you.", function (value) {
      const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = value;
      if(monday || tuesday || wednesday || thursday || friday || saturday || sunday) return true
    }),
    bestTimesOfDay: yup.object().shape({ morning: yup.boolean(), afternoon: yup.boolean(), evening: yup.boolean() })
      .test('oneOrMoreIsTrue', "Please select the best times of day for you.", function (value) {
      const { morning, afternoon, evening } = value;
      if(morning || afternoon || evening ) return true
    }),
    //possibleAppointmentDates: yup.array().of(yup.object().required("Please select at least one possible appointment date.")),
    firstTimeSeeingLoctician: yup.string().required("Please specify if this is your first time seeing a professional loctician."),
    whatServices: yup.string().required("Please specify what services you are looking for."),
    howManyDreadlocks: yup.string().required("Please specify how many dreadlocks you want."),
    hairThickness: yup.string().required("Please specify how thick your hair is."),
    hairType: yup.string().required("Please specify your hair type."),
    hairLength: yup.string().required("Please specify your hair length."),
    dreadlockSizeDesired: yup.string().required("Please specify your desired dreadlock size."),
    howDoYouKeepHair: yup.string().required("Please specify how you keep your hair."),
    scalpHealth: yup.string().required("Please specify your scalp health.")
  }))

})(NewDreadsForm));

const DayPicker_s = styled(DayPicker)`
  max-width: 290px;
  ${media.xs350` max-width: 100%; `}
  .DayPicker-Month { margin: 0; }
`;

const DropzoneDiv = styled.div`
    border: 1px dashed black;
    p {color: black;}
    cursor: pointer;
`;

const ThumbsAside = styled.aside`
    display: block;
    flexDirection: row;
    flexWrap: wrap;
    marginTop: 16px;
    cursor: default;
    h3 { margin: 0; }
`;

const ThumbDiv = styled.div`
    display: inline-flex;
    borderRadius: 2px;
    border: 1px solid #eaeaea;
    marginBottom: 8px;
    marginRight: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    boxSizing: 'border-box';
`;
const ThumbInner = styled.div`
    display: flex;
    minWidth: 0;
    overflow: hidden;

    img {
      display: block;
      width: auto;
      height: 100%;
    }
`;

const UploadPhotosDiv = styled.div`
  text-align: left;
  margin-bottom: 40px;
  p {margin-bottom: 10px; color: #4A90E2;}
`;

const CheckboxMainDiv = styled.div`
  text-align: left;
  margin-bottom: 40px;

  label { 
    padding: 0; 

    input {
      width: 30px;
    }
  }

`;

const StatusDiv = styled.div`
  p {
    color: #4BB543;
    font-weight: bold;
    text-align: center;
  }
`;


const ErrorMessage_Ext = styled(ErrorMessage)`
  color: #ff2323;
`;

const SubmitButton = styled(Button)`
  background-color: black!important;
  font-weight: bold!important;
  :hover { transform: translateY(3px); }

  p { margin: 0; }
`;
const LabelCol = styled(Col)`
  text-align: left;
  label { 
    margin: 0; 
    height: 100%;
    display: flex;
    align-items: center;
  } 
`;

const FormikForm = styled(Form)`
  label {
    p { margin: 0 15px 0 0; }
  }

  input, textarea {
    background: black!important;
    color: white!important;
    padding-left: 8px!important;
    width: 100%;
    &::placeholder { color: lightgray!important; }
    :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus 
      { transition: background-color 5000s ease-in-out 0s; -webkit-text-fill-color: white!important;}
    font-family: "Gochi Hand"!important;
    border-radius: 6px!important;
    border: none!important;
    outline: none;

    :focus {box-shadow: none; }
  }

  .row {
    margin: 0 0 15px 0;

    div { padding: 0!important; }
  }
`;

const DisplayRow = styled(Row)`

`;

const FluidContainer = styled(Container)`
  padding: 20px 0 20px 0!important;
  background: white;
`;
const InternalContainer = styled(Container)`
  text-align: center;
  padding: 0 15px 0 15px!important;
  h1 { 
    color: black; 
    margin: 0 0 20px 0;
  }
`;