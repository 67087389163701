import { css } from 'styled-components'

const sizes = {
  xxl1920: 1920,
  xxl1900: 1900,
  xxl1850: 1850,
  xxl1800: 1800,
  xxl1750: 1750,
  xxl1700: 1700,
  xxl1650: 1650,
  xxl: 1600,
  xl1550: 1550,
  xl1500: 1500,
  xl1450: 1450,
  xl1400: 1400,
  xl1350: 1350,
  xl1300: 1300,
  xl1250: 1250,
  xl: 1200,
  lg1150: 1150,
  lg1100: 1100,
  lg1060: 1060,
  lg1020: 1020,
  lg: 992,
  md950: 950,
  md900: 900,
  md850: 850,
  md800: 800,
  md: 768,
  sm740: 740,
  sm700: 700,
  sm650: 650,
  sm600: 600,
  sm: 576,
  xs500: 500,
  xs450: 450,
  xs400: 400,
  xs370: 370,
  xs350: 350,
  xs: 320
}

// iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})

export default media;