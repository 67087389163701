import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import heroBG_16by9 from '../media/herobg_16by9.jpg' /* current media width * .5625 to get min-height */
import heroBG_14by9 from '../media/herobg_14by9.jpg' /* current media width * .642 to get min-height */
import heroBG_94by77 from '../media/herobg_94by77.jpg' /* current media width * .819 to get min-height */
import heroBG_1by2 from '../media/herobg_1x2.jpg' /* current media width * .5625 to get min-height */



const HeroSection = (props) => ( 
  <FluidContainer fluid>
    <InternalContainer>
    </InternalContainer>
  </FluidContainer>
);



const FluidContainer = styled(Container)`
	padding: 0!important;
	background-color: white;
	background-image: url(${heroBG_1by2});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 440px;
	max-height: calc(100vh - 70px);
	width: 100%;
	height: auto;
	
	${media.xs370` min-height: 600px;`}
	${media.xs400` min-height: 605px;`}
	${media.xs450` min-height: 735px;`}
	${media.md` min-height: 628px; background-image: url(${heroBG_94by77});`}
	${media.lg` background-image: url(${heroBG_14by9}); min-height: 636px; `}
	${media.xl` background-image: url(${heroBG_16by9}); min-height: 675px; `}
	${media.xl1300` height: calc(100vh - 70px); background-attachment: fixed; `}
`;

const InternalContainer = styled(Container)`  `;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/

const HeroImg = styled.img`
	width: 100%;
	height: auto;
	margin: 0 auto;
	margin-bottom: 20px;
	display: block;

	// 70px top
	${media.lg`
		max-height: calc(100vh - 70px - 120px - 210px);
		width: 80%;
	`}
	${media.xl`
		max-height: calc(100vh - 70px - 140px - 210px);
		width: 65%;
	`}
	${media.xxl`
		max-height: calc(100vh - 70px - 100px - 220px);
		width: 90%;
	`}
`;


export default HeroSection;