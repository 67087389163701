import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';
import MyNavbar from '../components/MyNavbar.js';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'

import HeroSection from '../components/HeroSection.js';
import AboutSection from '../components/AboutSection.js';
import ServicesSection from '../components/ServicesSection.js';
import ContactSection from '../components/ContactSection.js';
import BookAppointmentSection from '../components/BookAppointmentSection.js';
import GallerySection from '../components/GallerySection.js';
import FAQSection from '../components/FAQSection.js';
/*
import FeaturedSection from '../components/FeaturedSection.js';
import FeaturedTourSection from '../components/FeaturedTourSection.js';
import FacesSection from '../components/FacesSection.js';
import RecipesSection from '../components/RecipesSection.js';
import SyrupGradesSection from '../components/SyrupGradesSection.js';
import StorageNutritionAllergenSection  from '../components/StorageNutritionAllergenSection.js';
import VisitUsSection  from '../components/VisitUsSection.js';
*/
class Home extends Component {
  static propTypes = {
    scrollToSection: PropTypes.func.isRequired,
    setHomeIsMounted: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {

    };
  }
  
  componentDidMount() 
  { 
    this.props.setHomeIsMounted(); 
  }
  
  componentWillUnmount() { this.props.setHomeIsMounted(); }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
    	<FluidContainer fluid>
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <ContactSection />
        <BookAppointmentSection />
        <GallerySection />
        <FAQSection />
        {/*
        <FeaturedTourSection />
        <FeaturedSection products={this.props.products}/>
        <FacesSection />
        <RecipesSection />
        <StorageNutritionAllergenSection />
        <SyrupGradesSection scrollToSection={this.props.scrollToSection} scrollToSectionParam={this.props.match.params.scrollToSection} windowWidth={this.props.windowWidth}
         />
        
        <VisitUsSection />
        */}
        
        
      </FluidContainer>
		);
	}
}

export default withRouter(Home);

const FluidContainer = styled(Container)` padding: 0!important; `;

//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
`;
*/
