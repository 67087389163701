import React, {Component} from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import App from './App';

import NoMatch from './pages/NoMatch';
import Home from './pages/Home';
import BookAppointmentNewDreads from './pages/BookAppointmentNewDreads';
import BookAppointmentCustomExtensions from './pages/BookAppointmentCustomExtensions';
import BookAppointmentMaintenanceRepair from './pages/BookAppointmentMaintenanceRepair';

import Sitemap from './pages/Sitemap';

import axios from 'axios';
import scrollToElement from 'scroll-to-element';

const loadScript = require('simple-load-script');

export default class Routes extends Component {
  //static propTypes = {testProp: PropTypes.bool.isRequired}

  constructor(props) {
    super(props);

    this.state = {
      windowWidth: 0,
      googleMapsLoaded: false,
      googleMapsObject: null,
      previousRoute: {},
      currentRoute: {},
      homeIsMounted: false

    };
  }
  
  
  setHomeIsMounted = () => { this.setState({ homeIsMounted: !this.state.homeIsMounted }) }
  setPreviousRoute = (previousRoute) => { this.setState({ previousRoute }) }
  setCurrentRoute = (currentRoute) => { this.setState({ currentRoute }) }

  componentDidMount() {
    this.loadGoogleMaps();
    window.onresize = () => this.setWindowWidth();
    this.setWindowWidth();
  }
  componentWillUnmount() {
    window.onresize = null;
  }

  validatePhone = (value) => { return value.match(/^[\d-()]{7,}$/g); }
  validateZip = (value) => { return value.match(/^[\d]{5,}$/g); }

  loadGoogleMaps = () => {
    const googleMaps = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDtC1mrAf2pxlWesZTkee0YRm_70P1SRW8";
    let googleMapsLoaded = this.isScriptLoaded(googleMaps);

    if(!googleMapsLoaded) {
      loadScript({
        url: googleMaps
      })
      .then( (script) => {
        this.setState({
          googleMapsLoaded: true,
          googleMapsObject: window.google.maps
        });
      })
      .catch( (err) => {
        console.log(`GoogleMaps load error: ${err}`);
      })
    }
  }

  isScriptLoaded = (script) => {
    const scripts = document.getElementsByTagName('script');
      for (var i = scripts.length; i--;) {
          if (scripts[i].src == script) 
          {
            return true;
          }
      }

      return false;
  }

  scrollToSection = (idToScrollTo, offsetNum) => {
    let navigateTo = document.querySelector("#" + idToScrollTo);

    scrollToElement(navigateTo, {
      offset: offsetNum,
      align: "top",
      duration: 1000
    });
  }

  eventFire = (el, etype) => {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  setWindowWidth = () => { this.setState({ windowWidth: window.innerWidth });  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
		<BrowserRouter basename="/">
				<App setPreviousRoute={this.setPreviousRoute} setCurrentRoute={this.setCurrentRoute}
             previousRoute={this.state.previousRoute} currentRoute={this.state.currentRoute}
             homeIsMounted={this.state.homeIsMounted} scrollToSection={this.scrollToSection} >
					<Switch>
            <Route path='/Sitemap' header="Sitemap" component={Sitemap} />
            <Route path='/BookAppointmentNewDreads' header="Book Appointment New Dreadlocks" component={BookAppointmentNewDreads} />
            <Route path='/BookAppointmentMaintenanceRepair' header="Book Appointment Maintenance & Repair" component={BookAppointmentMaintenanceRepair} />

            <Route exact path='/:scrollToSection(About|Services|Contact|Gallery|BookAppointment|FAQ|HairChart)?' header="Home" 
              render={(props) => ( <Home products={this.state.products} scrollToSection={this.scrollToSection} 
                setHomeIsMounted={this.setHomeIsMounted} windowWidth={this.state.windowWidth} /> ) } />
          
						<Route path='empty' key="empty" component={null} />
						<Route path='/404' header="404" component={NoMatch} />
						
						<Redirect to="/404" />
					</Switch>
				</App>
		</BrowserRouter>	

		);
	}
}
