import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';
import { css } from 'styled-components'

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import scrollToElement from 'scroll-to-element';


export default class MyNavbarCollapse extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleIsOpen: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  aboutClick = () => { if(this.props.currentRoute.pathname == "/About") { this.scrollToSection("aboutSectionID", -65); } }
  servicesClick = () => { if(this.props.currentRoute.pathname == "/Services") { this.scrollToSection("servicesSectionID", -65); } }
  contactClick = () => { if(this.props.currentRoute.pathname == "/Contact") { this.scrollToSection("contactSectionID", -65); } }
  galleryClick = () => { if(this.props.currentRoute.pathname == "/Gallery") { this.scrollToSection("gallerySectionID", -65); } }
  faqClick = () => { if(this.props.currentRoute.pathname == "/FAQ") { this.scrollToSection("faqSectionID", -65); } }

  scrollToSection = (idToScrollTo, offsetNum) => {
    let navigateTo = document.querySelector("#" + idToScrollTo);

    scrollToElement(navigateTo, {
      offset: offsetNum,
      align: "top",
      duration: 1000
    });
  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
        <Collapse_s isOpen={this.props.isOpen} navbar>
          <Nav className="ml-auto" navbar >
            <NavItemNoDisplay768>
                <NavLink onClick={this.props.toggleIsOpen} >
                    <Span_Menu>MENU</Span_Menu>
                    <Img_CloseMenu src={require("../media/tinyXWhite.png")} ></Img_CloseMenu>
                </NavLink>
            </NavItemNoDisplay768>

            <NavItemNoDisplay768>
                <NavLink tag={Link} to="/">
                    <Span_NavLinkTitle className="red">HOME</Span_NavLinkTitle>
                </NavLink>
            </NavItemNoDisplay768>

            <NavItem>
              <NavLink tag={Link} to="/About">
                  <Span_NavLinkTitle className="orange">ABOUT</Span_NavLinkTitle>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/Services">
                  <Span_NavLinkTitle className="yellow">SERVICES</Span_NavLinkTitle>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/Contact">
                  <Span_NavLinkTitle className="green">CONTACT</Span_NavLinkTitle>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/BookAppointment">
                  <Span_NavLinkTitle className="blue">BOOK&nbsp;APPOINTMENT</Span_NavLinkTitle>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/Gallery">
                  <Span_NavLinkTitle className="purple">GALLERY</Span_NavLinkTitle>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/FAQ">
                  <Span_NavLinkTitle className="pink">FAQ<span style={{color: "white"}}>s</span></Span_NavLinkTitle>
              </NavLink>
            </NavItem>

          </Nav>
        </Collapse_s>
    );
  }
}

const Collapse_s = styled(Collapse)`
  padding: 8px 0 0 0;
  background-color: black;
  ${media.md` padding-top: 0; background-color: black; `}
  
  // Top level for all nav items
  li.nav-item {
    
    height: 70px;
    line-height: 70px;
    display: table;
    width: 100%;
    border-bottom: 1px solid #BCA368;
    flex-basis: 1%;
    // If IE 10+
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { flex-basis: auto; }

    ${media.md` 
        display: list-item; 
        width: auto; 
        border-bottom: none; 
        text-align: center;
        padding: 0 0px 0 0;
    `}

    ${media.lg` margin-left: 5px; `}
    ${media.xl` margin-left: 10px; `}
    ${media.xxl` margin-left: 30px; `}

    a.nav-link {
      padding: 0;
      height: 100%;
      font-size: 30px!important;
      :hover { transform: translateY(3px); }

      img {
        display: inline-block;
        float: right;
        width: 20px;
        height: 20px;
        margin: 20px 10px 0 0;
        cursor: pointer;
      }

      span {
        :hover { text-decoration-line: underline;}
        ${media.sm` font-size: 34px; `}
        ${media.md` font-size: 17px;  `}
        ${media.lg` font-size: 21px; `}
        ${media.xl` font-size: 25px; `}
        ${media.xxl` font-size: 32px; `}

      }
    }
  }

`;

const NavItemNoDisplay768 = styled(NavItem)` ${media.md` display: none!important; `}; `;

const Span_Menu = styled.span`
  color: white;
  margin-left: 16px;
`;
const Img_CloseMenu = styled.img`
  cursor: pointer;
`;

const Span_NavLinkTitle = styled.span`
  cursor: pointer;
  margin-left: 16px;
  :hover { text-decoration-line: underline; }

`;
//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/