import React from 'react';
import Routes from './Routes';
import registerServiceWorker from './registerServiceWorker';
import { render } from 'react-snapshot'
import 'bootstrap/dist/css/bootstrap.css';

render(
	<Routes />, 
	document.getElementById('root')
);

registerServiceWorker();
