import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './App.css';
import MyNavbar from './components/MyNavbar.js';
import Footer from './components/Footer.js';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-103833236-5');

class App extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setCurrentRoute: PropTypes.func.isRequired,
    setPreviousRoute: PropTypes.func.isRequired,
    scrollToSection: PropTypes.func.isRequired,
    homeIsMounted: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {

    };
  }
  
  componentDidMount() {

    this.props.history.listen((location, action) => {
      this.props.setPreviousRoute(this.props.location);
      this.props.setCurrentRoute(location);
      setTimeout(() => { this.handleRouteChangeStuff(); }, 0) })
  }

  handleRouteChangeStuff = () => {
      //console.log(this.props.previousRoute);
      //console.log(this.props.currentRoute);
      this.fireGoogleAnalyticsPageChange();

      // Scrolls to top on every route change unless you are leaving cart.
      this.tryScrollToTopOnRouteChange();
      
      // If a scroll to section is clicked on while you're on home page, just scroll to it.
      this.tryScrollToSectionFromSamePage();

      // If a scroll to section is clicked on while you're on another page: wait for timeout to load page, then scroll to it.
      this.tryScrollToSectionFromOtherPage();


      //if(this.state.currentRoute)
      /*
      if ( (this.props.location.hash.length < prevProps.location.hash.length) 
        && ! this.props.location.hash.toUpperCase().includes("CONFIRM") && !prevProps.location.hash.toUpperCase().includes("CONFIRM") ) {

        var location = this.props.location;
        location.hash = "";
      */

        //this.props.history.push({ pathname: "/empty" }); maybe need to reimplement for some reason payment wise?
        /*
        setTimeout(() => {
          this.props.history.replace({pathname: location.pathname})
        });
        */

  }

  fireGoogleAnalyticsPageChange = () => { ReactGA.pageview(this.props.currentRoute.pathname); }

  tryScrollToTopOnRouteChange = () => {
    // Scrolls to top on every route change unless you are leaving cart.
      if ( 
            (this.props.currentRoute.pathname !== this.props.previousRoute.pathname) && 
            (   this.props.currentRoute.pathname == "/" ||
                this.props.currentRoute.pathname == "/NewDreads" ||  this.props.currentRoute.pathname == "/BookAppointmentNewDreads" || 
                this.props.currentRoute.pathname == "/BookAppointmentMaintenanceRepair"
            )
          ) 
      {
        window.scrollTo(0, 0);      
      }
  }

  tryScrollToSectionFromSamePage = () => {
     // If a scroll to section is clicked on while you're on home page, just scroll to it.
      if(this.props.previousRoute.pathname == "/" || this.props.previousRoute.pathname == "/About" || this.props.previousRoute.pathname == "/Services"
         || this.props.previousRoute.pathname == "/Contact" || this.props.previousRoute.pathname == "/BookAppointment" 
         || this.props.previousRoute.pathname == "/Gallery" || this.props.previousRoute.pathname == "/FAQ" || this.props.previousRoute == "/HairChart") {
        this.performScroll();
      }
  }

  tryScrollToSectionFromOtherPage = () => {
      // If a scroll to section is clicked on while you're on another page: wait for timeout to load page, then scroll to it.
      if( (this.props.previousRoute.pathname != "/" && this.props.previousRoute.pathname != "/About" && this.props.previousRoute.pathname != "/Services"
           && this.props.previousRoute.pathname != "/Contact" && this.props.previousRoute.pathname != "/BookAppointment" 
           && this.props.previousRoute.pathname != "/Gallery" && this.props.previousRoute.pathname != "/FAQ" && this.props.previousRoute != "/HairChart") )
      {
        setTimeout(() => {
          this.performScroll();
        }, 1000)
      }
  }

  performScroll = () => 
  {
        if(this.props.currentRoute.pathname == "/" && this.props.previousRoute.pathname == "/") { window.scrollTo(0, 0); }
        if(this.props.currentRoute.pathname == "/About") { this.props.scrollToSection("aboutSectionID", -65); }
        if(this.props.currentRoute.pathname == "/Services") { this.props.scrollToSection("servicesSectionID", -65); }
        if(this.props.currentRoute.pathname == "/Contact") { this.props.scrollToSection("contactSectionID", -65); }
        if(this.props.currentRoute.pathname == "/BookAppointment") { this.props.scrollToSection("bookappointmentSectionID", -65); }
        if(this.props.currentRoute.pathname == "/Gallery") { this.props.scrollToSection("gallerySectionID", -65); }
        if(this.props.currentRoute.pathname == "/FAQ") { this.props.scrollToSection("faqSectionID", -65); }
        if(this.props.currentRoute.pathname == "/HairChart") { this.props.scrollToSection("hairChartID", -65); }
  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
      <div>
        {/*ExampleObject*/}
        <Helmet>
          <title>Dreadful Bliss: Crochet Dreads for All Hairstyles</title>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Crochet dreadlocks for all hairstyles. New dreads, maintenance, hair wraps, extensions, beads, and more.
              Ask me questions or schedule an appointment today!" />
            <meta name="robots" content="index,follow,imageindex,noodp" />
            <link rel="canonical" href="https://www.dreadfulbliss.com" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css" integrity="sha384-rwoIResjU2yc3z8GV/NPeZWAv56rSmLldC3R/AZzGRnGxQQKnKkoFVhFQhNUwEyJ" crossorigin="anonymous" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Gochi+Hand" /> 
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Permanent+Marker" /> 
        </Helmet>

        {this.props.location.pathname !== "/404" ? <MyNavbar {...this.props} /> : ""}

        {this.props.children } 

        {this.props.location.pathname !== "/404" ? <Footer /> : ""}
      </div>
    );
  }
}





export default withRouter(App);