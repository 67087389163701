import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';
import ContactForm from './ContactForm'

export default class ContactSection extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  render()  {
    //const FormikForm = ( <div></div> );

    return (
	  <FluidContainer id="contactSectionID" fluid>
	    <InternalContainer>
	      <h1>
	      	<span className="red">C</span>
	      	<span className="orange">O</span>
	      	<span className="yellow">N</span>
	      	<span className="green">T</span>
	      	<span className="blue">A</span>
	      	<span className="purple">C</span>
	      	<span className="pink">T</span>
	      	&nbsp;
	      	<span className="red">M</span>
	      	<span className="orange">E</span>
	      </h1>
		
		  <ContactForm />
		  
	    </InternalContainer>
	  </FluidContainer>

		);
	}
}

const FluidContainer = styled(Container)`
	padding: 20px 0 20px 0!important;
	background: white;
`;
const InternalContainer = styled(Container)`
	text-align: center;
	h1 { 
		color: black; 
		margin: 0 0 20px 0;
	}
`;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/