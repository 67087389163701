import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import 'whatwg-fetch';
import { Formik, Field, Form, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';


const sendEmail = (values) => {
  const URL = 'https://gv8d2fbxx4.execute-api.us-east-1.amazonaws.com/Production/connect';
  const emailData = { email: values.email };

  fetch(URL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify({emailData})
  })
  .then(function (response) {
    console.log('Response: ' + response.json());
  })
  .then(function (result) {
    console.log('Result: ' + result);
  })
  .catch(function(error) {
    console.log('Error: ' + error);
  });
}

const ConnectForm = (props) => {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, status, setStatus, setFieldValue } = props;

    return (
      <FluidContainer fluid>
        <InternalContainer>
          <h3 className="pink">The First to Know</h3>
          <p className="pink">Get email offers & the latest news from me, at Dreadful Bliss!</p>

          <FormikForm onSubmit={handleSubmit} autoComplete="on">


            <Row>
              <LabelCol xs="12" sm="4" md="12">
                <label htmlFor="email"><h3 className="pink">Email Address:</h3></label>
              </LabelCol>
              <FieldCol xs="12" sm="8" md="12">
                <Field name="email" />
                <ErrorMessage_Ext name="email" component="div" />
              </FieldCol>
            </Row>
            
            <StatusDiv><p>{status}</p></StatusDiv>
            <SubmitButton disabled={isSubmitting} type="submit"><p>Submit</p></SubmitButton>

          </FormikForm>

        </InternalContainer>
      </FluidContainer>
      );
    };


export default withFormik({
  // Initial Values
  mapPropsToValues: (props) => ({ email: '' }),

  // Validation
  
  validate: values => {
    //console.log("validating");
    //console.log(values);
  },
 
  // Submit
  handleSubmit: (values, { setSubmitting, setStatus, resetForm }) => {
    setTimeout(() => {
      setSubmitting(true);
      sendEmail(values);
      resetForm();
      setStatus("I received your email. Thank you!")
    }, 1000);
  },

  displayName: "Dreadful Bliss Connect",
  validationSchema: (yup.object().shape({
    email: yup.string().email().required("Please enter your email address.")
  }))

})(ConnectForm);


const FieldCol = styled(Col)`
  padding: 0!important;
  margin: 0!important;

  input { max-width: 300px;}
`;

const FluidContainer = styled(Container)`
  padding: 0px 0 0 0!important;
  
`;
const InternalContainer = styled(Container)`
  margin: 0 auto!important;
  padding: 0!important;

  h3 { margin-bottom: 30px; }

  p {

  }

`;






const StatusDiv = styled.div`
  p {
    color: #4BB543;
    font-weight: bold;
    text-align: center;
  }
`;


const ErrorMessage_Ext = styled(ErrorMessage)`
  color: #ff2323;
`;

const SubmitButton = styled(Button)`
  background-color: black!important;
  font-weight: bold!important;
  :hover { transform: translateY(3px); }

  p { margin: 0!important; color: #BD10E0; }
`;
const LabelCol = styled(Col)`
  text-align: left;
  padding: 0!important;
  margin: 0 0 12px 0!important;

  label { 
    margin: 0; 
    height: 100%;
    display: flex;
    align-items: center;
    
  } 
`;

const FormikForm = styled(Form)`
  label {
    h3 { margin: 0 auto; }
  }

  input, textarea {
    background: black!important;
    color: white!important;
    padding-left: 8px!important;
    width: 100%;
    ::placeholder { color: lightgray!important; }
    :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:active, :-webkit-autofill:focus 
      { transition: background-color 5000s ease-in-out 0s; -webkit-text-fill-color: white!important;}

      
    font-family: "Gochi Hand"!important;
    border-radius: 6px!important;
    border: none!important;
    outline: none;

    :focus {box-shadow: none; }
  }

  .row {
    margin: 0 0 15px 0;
  }
`;

const DisplayRow = styled(Row)`

`;
