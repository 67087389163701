import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';
import ConnectForm from '../components/ConnectForm.js';
import { SocialIcon } from 'react-social-icons';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
  //static propTypes = {testProp: PropTypes.bool.isRequired}

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
      <FluidContainer  fluid>
        <InternalContainer>

          <Row>
            <LeftCol xs="12" md="4">
              <div>
                <ContactDiv>
                  <h3 className="red">Contact Me</h3>
                      <a className="red" target="_blank" href="mailto:dreadfulblisscasey@gmail.com">dreadfulblisscasey@gmail.com</a>
                </ContactDiv>

                <LocationInfoDiv>
                  <h3 className="orange">Wellsboro, PA 16901</h3>
                  <a className="orange" target="_blank" 
                    href="https://www.google.com/maps/place/Main+St,+Wellsboro,+PA+16901/@41.7475311,-77.3042428,17z/data=!3m1!4b1!4m5!3m4!1s0x89ce2ebd8cda1d87:0x35a17a3524054ae7!8m2!3d41.7475311!4d-77.3020541">
                    Google Maps Directions</a>
                </LocationInfoDiv>


                <SocialMediaDiv>
                    {/*<img src={require("../media/facebook.jpg")}></img>*/}
                    <h3 className="green">Visit me on Facebook and Instagram!</h3>
                    <SocialIcon_Facebook url="https://www.facebook.com/caseydreadfulblisshume" className="green" />
                    <SocialIcon_Instagram url="https://www.instagram.com/dreadfulblissdreads" className="green" />
                </SocialMediaDiv>
                  
              </div>
            </LeftCol>

            <MiddleCol xs="12" md="4">
              
              <div>
                <h3 className="purple">My Goal</h3>
                <p className="purple">
                  My goal is to make dreadful bliss attainable for all through customization, affordability, and all natural crochet methods.
                </p>
                <img src={require("../media/smiley.png")}></img>
              </div>
            </MiddleCol>

            <RightCol xs="12" md="4">
              <div>
                <ConnectForm />
              </div>
            </RightCol>
          </Row>

        </InternalContainer>

        <BottomLinkAndDesignedByContainer_Fluid fluid>
          <BottomLinkAndDesignedByContainer>
            <Row>
              <LinksCol xs="12" md="8" lg="7" >
                <Link to="/" className="red">HOME</Link>
                {' / '}
                <Link to="/About" className="orange">ABOUT</Link>
                {' / '}
                <Link to="/Services" className="yellow">SERVICES</Link>
                {' / '}
                <Link to="/Contact" className="green">CONTACT ME</Link> 
                {' / '}
                <Link to="/BookAppointment" className="blue">BOOK APPOINTMENT</Link>
                {' / '}
                <Link to="/Gallery" className="purple">GALLERY</Link>
                {' / '}
                <Link to="/FAQ" className="pink">FAQs</Link>
              </LinksCol>
              <RightSideCol xs="12" md="4" lg="5">
                <p> Dreadful Bliss &copy;{new Date().getFullYear()}. All Rights Reserved. </p>
              </RightSideCol>
              <RightSideCol xs="12" md="12" lg="12">
                <DesignedByA href="https://www.lotus29.com" target="_blank">Website designed by Lotus29</DesignedByA>
              </RightSideCol>
            </Row>
          </BottomLinkAndDesignedByContainer>
        </BottomLinkAndDesignedByContainer_Fluid>
      </FluidContainer>
    );
  }
}

const RightSideCol = styled(Col)`
 
`;

const LinksCol = styled(Col)`
  ${media.md` text-align: left!important; `}
`;

const BottomLinkAndDesignedByContainer_Fluid = styled(Container)` 
  background-color: black; 
`;

const BottomLinkAndDesignedByContainer = styled(Container)` 
  padding: 20px 0 0 0;

  p { 
    margin-bottom: 0; 
    text-align: center!important; 
    ${media.md` text-align: right!important; `}
  }

  .col-12 {  
    padding: 0; margin-bottom: 10px; 

  }
`;


const SocialIcon_Facebook = styled(SocialIcon)`
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow, border, color, opacity, transform;
  transition-timing-function: ease-in-out;
  margin-right: 12px;
  
  :hover {
    .social-svg-mask {
      fill: rgb(59, 89, 152)!important;
    }
  }
`;

const SocialIcon_Instagram = styled(SocialIcon)`
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow, border, color, opacity, transform;
  transition-timing-function: ease-in-out;
  margin-right: 12px;
  
  :hover {
    .social-svg-mask {
      fill: rgb(59, 89, 152)!important;
    }
  }
`;

const ContactDiv = styled.div`
  margin-bottom: 20px;
  p { margin-bottom: 0; }
  ${media.md` a { overflow-wrap: break-word; } `}
`; 

const LocationInfoDiv = styled.div`
  margin-bottom: 20px;
  p { margin-bottom: 0; }
`;

const SocialMediaDiv = styled.div`
  h3 { margin-bottom: 8px!important;}
  img {
    height: 35px;
    width: 35px;
    margin-right: 5px;
  }
`;

const InternalContainer = styled(Container)`
  padding-bottom: 0px;

  .col-12 {  margin-bottom: 20px; }

  h3 { 
    margin-bottom: 0;

  }
`;

const FluidContainer = styled(Container)`
  padding: 40px 0 0 0!important;
  background-color: white;
  text-align: center!important;

`;



const DesignedByA = styled.a`
  font-family: "Permanent Marker"!important;
  ${media.md` float: right; `}
`;



const LeftCol = styled(Col)`

`;

const MiddleCol = styled(Col)`
  p {
    margin: 0;
    text-align: center;
  }
  ${media.md` h3 { margin-bottom: 6px; `}
  ${media.lg` h3 { margin-bottom: 4px; `}
`;

const RightCol = styled(Col)`
  p { margin-bottom: 12px; text-align: center; }
  ${media.md` h3 { margin-bottom: 6px; `}
  ${media.lg` h3 { margin-bottom: 4px; `}
`;







//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/