import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'
import MaintenanceRepairForm from '../components/MaintenanceRepairForm'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';


class BookAppointmentMaintenanceRepair extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      possibleAppointmentDates: [],
      formLoadingPopupOpen: false
    };
  }

  onAfterOpen = () => { 
    document.getElementsByTagName("button").onclick = 
    (() => {
      document.getElementByClassName("sa-success").addClass("hide");
      setTimeout(() =>  {
        document.getElementByClassName("sa-success").removeClass("hide");
      }, 10);
    });
  }

  formLoadingPopupClosing = () => { this.setState({ formLoadingPopupOpen: false }); }
  formLoadingPopupOpening = () => { this.setState({ formLoadingPopupOpen: true }); }
  
  handleDayClick = (day, {selected} ) => {
    const newPossibleAppointmentDates = this.state.possibleAppointmentDates;

    if (selected) {
      const selectedIndex = newPossibleAppointmentDates.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      newPossibleAppointmentDates.splice(selectedIndex, 1);
    } else {
      if(newPossibleAppointmentDates.length <=4) { newPossibleAppointmentDates.push(day); }
      
    }
    this.setState({ possibleAppointmentDates: newPossibleAppointmentDates});
  }

  goToHome = () => { this.props.history.push('/'); }
  
  render() {
    //const ExampleObject = ( <div></div> );

    return (
      <FluidContainer fluid>
        <Helmet>
          <title>Dreads Maintenance Appointment - Dreadful Bliss</title>
            <meta name="description" content="Schedule a dreadlocks maintenance appointment!" />
        </Helmet>
        <InternalContainer>
          <h1>Dreadlocks Maintenance / Repair Form</h1>

          <HeaderP>PLEASE FILL OUT ALL THE FOLLOWING INFORMATION. THE MORE INFORMATION YOU GIVE, THE BETTER IT IS FOR YOU AND ME!</HeaderP>
          <MaintenanceRepairForm possibleAppointmentDates={this.state.possibleAppointmentDates} handleDayClick={this.handleDayClick} goToHome={this.goToHome}
            onAfterOpen={this.onAfterOpen} formLoadingPopupOpening={this.formLoadingPopupOpening} formLoadingPopupOpen={this.state.formLoadingPopupOpen} 
            formLoadingPopupClosing={this.formLoadingPopupClosing}
          />

        </InternalContainer>
      </FluidContainer>
    );
  }
}

export default withRouter(BookAppointmentMaintenanceRepair);

const HeaderP = styled.p`
  color: black; 
  text-align: center;
`;

const FluidContainer = styled(Container)`
  padding: 20px 0 20px 0!important;
  background: white;
`;
const InternalContainer = styled(Container)`
  text-align: center;
  h1 { 
    color: black; 
    margin: 0 0 20px 0;
  }
`;


//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/