import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';

export default class FAQSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faqList: [],
      faqOpenArray: []
    };
  }

  componentDidMount() {
	this.setFaqList();
  }

  setFaqList = () => {
    axios.get('https://s3.amazonaws.com/dreadfulblissstorage/faq/FAQList.json')
      .then(response => {
        this.setState({
          faqList: response.data
        }, () => { this.createFaqOpenArray(); } );
        
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error);
      });
  }

  createFaqOpenArray = () => {
    let faqOpenArray = [];
    for(let i=0; i<this.state.faqList.length; i++) {
        faqOpenArray.push(false);
    }

    this.setState({ faqOpenArray });
  }

  faqCollapseToggle = (index) => {

    // If the element is selected and clicked, close it.
    let currentlySelectedIndex;
    let faqOpenArray = this.state.faqOpenArray;

    for(let i=0; i<faqOpenArray.length; i++) 
    {
      if(faqOpenArray[i] == true)
      {
        currentlySelectedIndex = i;
      }
    }
    if(currentlySelectedIndex == index) {
      faqOpenArray[index] = false;
    }
    else 
    // If a different element was clicked, open the new one & close all others.
    {
      for(var i=0; i<faqOpenArray.length; i++) 
      {
        faqOpenArray[i] = false;
      }
      faqOpenArray[index] = true;
    }

    this.setState({ faqOpenArray });
  }

  render() {
    const FAQView = this.state.faqList.map((faq, index) => {
      return <ItemDropdownContainer containerOpen={this.state.faqOpenArray[index]} key={index} 
        onClick={() => this.faqCollapseToggle(index)}>
        <Dropdown toggle={() => ''} >
          <DropdownToggle caret>
            {/*<h2 dangerouslySetInnerHTML={{__html: faq.q}}></h2>*/}
            <h2 className="pink">{faq.q}</h2>
          </DropdownToggle>
        </Dropdown>
        <Collapse isOpen={this.state.faqOpenArray[index]}>
            <p>
              {faq.a} &nbsp;
              {faq.id == 5 ? <Link className="blue" style={{fontWeight: "bold"}} to="/HairChart">Hair Comparison Chart</Link> 
                : "" }
            </p>
        </Collapse>
      </ItemDropdownContainer>
    })


    return (
	  <FluidContainer id="faqSectionID" fluid>
	    <InternalContainer>
	      <h1>
	      	<span className="red">F</span>
	      	<span className="orange">A</span>
	      	<span className="yellow">Q</span>
	      	<span className="green">s</span>
	      </h1>

		  {FAQView}

	    </InternalContainer>
	  </FluidContainer>

		);
	}
}


const FluidContainer = styled(Container)`
	padding: 20px 0 20px 0!important;
`;
const InternalContainer = styled(Container)`
	text-align: center;
	h1 { 
		color: black; 
		margin: 0 0 20px 0;
	}
`;

const ItemDropdownContainer = styled(({ containerOpen, ...rest }) => <Container {...rest} />)`
  margin-bottom: 10px;
  text-align: left;
  background-color: ${a => a.containerOpen ? "black" : "white"};
  cursor: pointer;
  border-radius: 6px;

  .dropdown {
    background-color: ${a => a.containerOpen ? "black" : "white"};
    height: 100px;
    line-height: 100px;

    button {
      width: 100%;
      padding: 0;
      background: none;
      border: none;
      vertical-align: middle;
      word-break: break-all;
      white-space: normal;
      cursor: pointer;
      

      h2 {
        margin: 0;
        font-size: 20px;
        ${media.lg` font-size: 22px; `}
        text-align: left;
        color: black;
        font-weight: bold;
      }

      ::after {
        float: right;
        margin-top: -9px;
          border-top-width: ${a => a.containerOpen ? 0 : ""};
          border-bottom: ${a => a.containerOpen ? "4.8px solid white" : ""};
      }

      :focus {
        box-shadow: none;
      }
    }
  }
  .collapse, .collapsing {
    background-color: ${a => a.containerOpen ? "black" : "white"};

    p {
      margin-bottom: 0px;
      color: white!important;
    }
  }
`;


/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/