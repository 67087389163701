import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';

export default class AboutSection extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }




  render() {
    //const ExampleObject = ( <div></div> );

    return (
	  <FluidContainer id="aboutSectionID" fluid>
	    <InternalContainer>
	      <h1>
	      	<span className="red">A</span>
	      	<span className="orange">B</span>
	      	<span className="yellow">O</span>
	      	<span className="green">U</span>
	      	<span className="blue">T</span>
	      	&nbsp;
	      	<span className="purple">T</span>
	      	<span className="pink">H</span>
	      	<span className="red">E</span>
	      	&nbsp;
	      	<span className="orange">O</span>
	      	<span className="yellow">W</span>
	      	<span className="green">N</span>
	      	<span className="blue">E</span>
	      	<span className="purple">R</span>
	      </h1>

		  <Row1>
		  	<Col xs="12" md={{size: 4, order: 8}} xl="5">
				<img src={require("../media/about1.jpg")}></img>
				<img src={require("../media/about2.jpg")} className="d-none d-sm-inline d-lg-inline d-xl-none"></img>
			</Col>
			<Col xs="12" md={{size: 8, order: 4}} xl="7">
				<p>
					Hello all! My name is Casey Hume. For as long as I can remember, I have been in love with dreadlocks.
					I have always loved the fact that they were so unique and free. In 2018, I finally fulfilled my dream and got dreadlocks! 
					Before I got my dreads, I did a lot of research on types of dreads and made the decision to go with crocheted dreadlocks due to 
					the fact that they are all natural. This means my dreadlocks have no chemicals or wax in them, and they are created by hand with just a crochet hook.
					One thing I realized on my dreaded journey was that there are not many places at all in the U.S. that create crocheted dreadlocks.
					I personally had to travel over four hours both ways to get mine. This really opened my eyes to the need for more locticians. 
					For the last several months, I have underwent a journey to learn how to create and maintenance crocheted dreads.
					I have received a certificate through THE DREAD SHED DREADLOCKS crocheted dreadlocks training group which is up in my shop! 
					I am located in the small tourist town of WELLSBORO, PA. I have a private in-home studio set up and waiting. 
					I want nothing more than to make you feel the same DREADED BLISS my dreads have brought me!
				</p>
			</Col>
		  </Row1>
			
		  <Row2>
		  	<Col xs="12" md="6">
				<img src={require("../media/about3.jpg")}></img>
			</Col>
		  	<Col xs="12" md="6">
				<img src={require("../media/about4.jpg")}></img>
			</Col>
		  </Row2>

		  <Row3>
		  	<Col xs="12" md="4">
				<img src={require("../media/about5.jpg")}></img>
			</Col>
		  	<Col xs="12" md="4">
				<img src={require("../media/about6.jpg")}></img>
			</Col>
		  	<Col xs="12" md="4">
				<img src={require("../media/about7.jpg")}></img>
			</Col>
		  </Row3>
		  
		  <p>I OFFER MANY DIFFERENT STYLES OF SEATING TO ENSURE YOUR COMFORT FOR YOUR WHOLE VISIT</p>
		  
	    </InternalContainer>
	  </FluidContainer>

		);
	}
}

const Row1 = styled(Row)`
	margin-bottom: 15px; 

	img {
		// 1 x 1.4 ratio for images. 200 x 280 is good for smallest spot
		width: 100%;
		height: auto;
		margin: 0 0 15px 0;
		border-radius: 6px;
		
		${media.xs450` width: auto; height: 500px; `}
		${media.sm ` margin: 0 20px 15px 20px; height: 550px;`}
		${media.md ` margin: 0 0 15px 0; width: 100%; height: auto;`}

	}
`;

const Row2 = styled(Row)`
	// 1.3 x 1 ratio for rectangle. 260 x 200 -> 520 x 400
	img {
		width: 100%
		height: auto;
		margin: 0 0 15px 0;
		border-radius: 6px;
	}

	${media.md` margin-bottom: 15px; `}
`;

const Row3 = styled(Row)`
	// 1 x 1 ratio
	img {
		width: 100%;
		height: auto;
		margin: 0 0 15px 0;
		border-radius: 6px;

		${media.sm ` width: 400px; height: 400px;`}
		${media.md ` width: 100%; height: auto; `}
	}
`;


const FluidContainer = styled(Container)`
	padding: 20px 0 20px 0!important;
`;
const InternalContainer = styled(Container)`
	text-align: center;
	h1 { 
		color: black; 
		margin: 0 0 20px 0;
	}
	p  { text-align: center; margin: 0;};
`;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/