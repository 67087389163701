import React, {Component} from 'react';
import PropTypes from 'prop-types';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import MyNavbarCollapse from './MyNavbarCollapse.js';
import stickybits from 'stickybits';
import { browserHistory } from 'react-router';


export default class MyNavbar extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    stickybits("#navbarID", {useStickyClasses: true} );
    this.setRouteChangeListener();
  }

  setRouteChangeListener = () => {
    this.props.history.listen( location => {
      this.setState({
        isOpen: false
      });
      
    });
  }

  toggleIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
          <Navbar_s id="navbarID" expand="md">
            <Navbar_MainContainer fluid>
              <NavbarBrand_s tag={Link} to="/" >
                <Logo src={require("../media/logo.png")}></Logo>
                {/*
                <LogoText>
                  <span                     >D</span>
                  <span className="red"     >R</span>
                  <span className="orange"  >E</span>
                  <span className="yellow"  >A</span>
                  <span className="green"   >D</span>
                  <span className="blue"    >F</span>
                  <span className="purple"  >U</span>
                  <span className="pink"    >L</span>
                  &nbsp;
                  <span                     >B</span>
                  <span                     >L</span>
                  <span                     >I</span>
                  <span                     >S</span>
                  <span                     >S</span>
                  &nbsp;
                  <span className="red" style={{fontWeight: "bold"}}    >&#x263A;</span>


                </LogoText>
              */}
              </NavbarBrand_s>


              <NavbarToggler_s onClick={this.toggleIsOpen} />
              <MyNavbarCollapse isOpen={this.state.isOpen} toggleIsOpen={this.toggleIsOpen} {...this.props} />
            </Navbar_MainContainer>
          </Navbar_s>
    );
  }
}

const Navbar_s = styled(Navbar)`
  background-color: black;
  padding: 0!important;
  z-index: 1;
  height: 70px;
  line-height: 70px;
  ${media.md` padding-right: 10px!important; `}

  // If IE 10+
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)  {
    min-width: 100%;
    &.js-is-stuck  {
      display: none;
    }
  }

  ${media.sm` `}
`;

const Logo = styled.img`
  width: auto;
  height: 24px;
  ${media.sm` height: 36px;`}
  ${media.md` height: 22px;`}
  ${media.lg` height: 30px;`}
  ${media.xl` height: 36px;`}
  ${media.xxl` height: 46px;`}

`;

const Navbar_MainContainer = styled(Container)`
`;

const NavbarBrand_s = styled(NavbarBrand)`
  padding: 0 0 0 16px!important;
  margin: 0!important;
  height: 70px;
  transition: all 0.5s;
  :hover { transform: translateY(3px); }

  span { font-size: 30px; }
  
  ${media.sm ` span { font-size: 40px; } `}
  ${media.md`  padding: 0!important; span { font-size: 24px; } `}
  ${media.lg` span { font-size: 32px; } `}
  ${media.xl` span { font-size: 41px; } `}
  ${media.xxl` span { font-size: 54px; } `}
`;

const NavItem_Bucket = styled(({ cartItemCount, ...rest }) => <NavItem {...rest} />)`
  cursor: pointer;
  padding-left: 10px;
  display: block;
  position: absolute;
  right: 50px;

  img { height: 45px; margin-bottom: 5px;}

  span {
    position: relative;
    line-height: 20px;
    font-size: 20px;
    right: ${a => a.cartItemCount < 10 ? "28px;" : "34px;"};
    top: 9px;
    color: yellow;
  }

  ${media.md`
    display: none;
  `}

  // If IE 10+
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)  {
    top: 0;
  }

`;



const LogoText = styled.span`
  vertical-align: middle;
  font-size: 14px;
  color: white;

  ${media.sm` font-size: 16px; `}
  ${media.lg` font-size: 20px; `}
  ${media.xl` font-size: 22px; `}
  ${media.xxl` font-size: 24px; `}
`;

const NavbarToggler_s = styled(NavbarToggler)`
  border: none;
  outline: none!important;
  cursor: pointer;
  margin: 9px 0 0 0!important;

  span.navbar-toggler-icon {
    font-size: 20px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
`;




//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/