import React, {Component} from 'react';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import axios from 'axios';

export default class ServicesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
	  <FluidContainer id="servicesSectionID" fluid>

			
		  <ContainerWhiteFluid fluid>
		  	<Container>
			      <h1>
			      	<span className="red">S</span>
			      	<span className="orange">E</span>
			      	<span className="yellow">R</span>
			      	<span className="green">V</span>
			      	<span className="blue">I</span>
			      	<span className="purple">C</span>
			      	<span className="pink">E</span>
			      	<span className="red">S</span>
			      </h1>
				  <p>
					  I SPECIALIZE IN THE CREATION AND MAINTENANCE OF CROCHETED DREADLOCKS.
					  <br />
					  THIS MEANS I NEVER USE ANY CHEMICALS OR WAXES ON DREADS. <span className="purple">#ALLNATURALDREADS</span>
				  </p>
			  </Container>
		  </ContainerWhiteFluid>
			
		  <ContainerNormal>
			  <p>DREADLOCK MAINTENANCE INCLUDES:</p>
			  <p className="red">tightening roots</p>
			  <p className="orange">smoothing out loops, bumps</p>
			  <p className="yellow">crcoheting / tucking in loose hairs</p>
			  <p className="green">thickening thin spots</p>
			  <p className="blue">palmrolling</p>
			  <p className="purple">separating dreads</p>
			  <p className="pink">some dread removal to start over</p>
			  <p className="LastP">typical dreadlock maintenance is $55.00 an hour</p>
			  
			  <p><Link to="/Contact" className="red">CONTACT ME </Link> WITH ANY QUESTIONS, CONCERNS, OR FOR MORE INFORMATION</p>
		  </ContainerNormal>

		  <ContainerWhiteFluid2 fluid>
		  	<Container>
			  <p className="red" style={{marginBottom: "0"}}>STARTING NEW DREADLOCKS:</p>
			  <p style={{color: "black"}}>
			  	I do quotes for starting new dreadlocks, so you will know the approximate price upfront, without any surprises.
			  	Prices vary due to hair length, hair thickness, desired dread thickness, etc. If you are interested in booking 
			  	an appointment with me, the form will have you answer questions about your hair, your desired dreads, and will have you upload 
			  	some pictures for me to assess and create a quote for you.
			  </p>
			  <p className="orange" style={{marginBottom: "0"}}>STARTING NEW DREADLOCKS IS TYPICALLY $65.00 AN HOUR</p>
			  <p style={{color: "black", paddingBottom: "10px"}}>
			  	Things to keep in mind - Creating new dreadlocks is a process that takes time. Each dreadlock is a work of art and needs to be perfected.
			  	Since I am a one woman show, this can take some time. If you have long hair, below shoulder length, this can take 12-16 hours or even more 
			  	if you have thick hair. A full head of dreads is a process that takes more than one day. I will be happy to help you plan your trip details!
			  </p>
			</Container>
		  </ContainerWhiteFluid2>

		  <ContainerNormal2 id="hairChartID">
			  <p className="green">DREADLOCKS EXTENSION SERVICES:</p>
			  <p>Not all dreadlock extensions are the same. There are some things to keep in mind if you are thinking about getting extensions. 
			  	Are you going to order premade dreadlock extensions or do you want me to make them for you? Upon request, 
			  	I will make custom dreadlock extensions for you. Please use the <Link to="/Contact" className="red">contact form</Link> to email me directly with exactly what you want, 
			  	so we can discuss this further. You can also order premade dreadlock extensions. Keep in mind, I create crocheted dreadlock 
			  	extensions, so you want to make sure the extensions you order are created using the same method. 
			  	If you are having trouble finding premade dreadlock extensions that fit what you're looking for, contact me!
			  	I'll be happy to help you through the process!
			  	<br />
			  	Are you struggling to decide between synthetic and human hair dreadlocks? Take a look below at the pros and cons.
			  </p>
			  <p className="yellow" style={{marginBottom: "30px"}}>TYPICAL DREADLOCK EXTENSION SERVICE IS $65 AN HOUR</p>
			  
			  <h3>HUMAN HAIR</h3>
			  <RowHuman>
				<ColSmiley xs="12" md="6">
					<img src={require("../media/smiley.png")}></img>
					<p className="red">Looks just like human hair</p>
					<p className="orange">Feels like human hair</p>
					<p className="yellow">Dreads just like human hair</p>
					<p className="green">Can be dyed and styled like human hair</p>
				</ColSmiley>
				<Col xs="12" md="6">
					<img src={require("../media/frowny.png")}></img>
					<p className="blue">Often relies on potentially unethical human labor or sacrifice for production / resale</p>
					<p className="purple">Some may dislike the idea of someone else's hair on their head</p>
					<p className="pink">Is significantly more expensive than synthetic hair</p>
				</Col>
			  </RowHuman>

			  <h3>SYNTHETIC HAIR</h3>
			  <RowSynthetic>
				<ColSmiley xs="12" md="6">
					<img src={require("../media/smiley.png")}></img>
					<p className="red">Can be 'sealed' to appear like mature dreadlocks</p>
					<p className="orange">Significantly cheaper than human hair</p>
					<p className="yellow">Doesn't rely on potentially unethical human labor or sacrifice for production / resale</p>
				</ColSmiley>
				<Col xs="12" md="6">
					<img src={require("../media/frowny.png")}></img>
					<p className="green">Feels different than natural hair</p>
					<p className="blue">May look different than natural hair (but usually only to a trained professional)</p>
					<p className="purple">The manufacturing of synthetic hair is hazardous to the environment</p>
					<p className="pink">Will be melted by traditional hair styling tools</p>
				</Col>
			  </RowSynthetic>
		  </ContainerNormal2>

	  </FluidContainer>

		);
	}
}

const RowHuman = styled(Row)`
	margin-bottom: 50px;

	${media.md` margin-bottom: 40px; `}
`;

const RowSynthetic = styled(Row)`
	margin-bottom: 20px;

	${media.md``}
`;

const ColSmiley = styled(Col)`
	margin-bottom: 20px;

	${media.md` margin-bottom: 0; `}
`
const ContainerWhiteFluid = styled(Container)`
	background: white;
	p { color: black; text-align: center;}
	padding: 10px 0 0px 0!important;
`;
const ContainerNormal = styled(Container)`
	padding: 0 15px 20px 15px;
	p { text-align: center; margin: 0;}
	.LastP { margin: 0 0 15px 0; }
`;
const ContainerWhiteFluid2 = styled(Container)`
	background: white;
	p { text-align: center;}
	padding: 10px 0 0px 0!important;
`;
const ContainerNormal2 = styled(Container)`
	padding: 0 15px 20px 15px;
	p { text-align: center; margin: 0;}
	.LastP { margin: 0 0 15px 0; }
	h3 { color: white; }
`;


const DisplayRow = styled(Row)`

`;

const FluidContainer = styled(Container)`
	padding: 20px 0 20px 0!important;
	text-align: center;
	h1 { 
		color: black; 
		margin: 0 0 20px 0;
	}
`;
const InternalContainer = styled(Container)`

`;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
  display: ${a => a.listSelected ? "block" : "none"};
  ${media.sm` background-image: url(${smHeroImage}); height: 362px; `}
`;
*/