import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import media from '../MediaQueries.js';
import styled from 'styled-components';

import { Collapse, Input, InputGroup, InputGroupButton, FormFeedback, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,
        NavLink, Container, Row, Col, Jumbotron, Button, ButtonGroup, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';


export default class NoMatch extends Component {
  //static propTypes = {testProp: PropTypes.bool.isRequired}

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    //const ExampleObject = ( <div></div> );

    return (
    	<FluidContainer className="p-0" fluid>
        <Helmet>
          <title>No Page Found - Dreadful Bliss</title>
        </Helmet>
        <InternalContainer>
	        
      		{/*ExampleObject*/}
      		<p>Oops! Dreadful Bliss was unable to locate that page.</p>
          <p>If you would like to return to our home page, please click here: </p>
          <Link to="/">www.dreadfulbliss.com</Link>
        </InternalContainer>
      </FluidContainer>
		);
	}
}

const FluidContainer = styled(Container)` padding: 0!important;  `;
const InternalContainer = styled(Container)`  `;

//const Div_s = styled.div``;

/*
const ListViewContainer = styled(({ listSelected, ...rest }) => <Container {...rest} />)`
	display: ${a => a.listSelected ? "block" : "none"};
`;
*/
